import React, { Component } from 'react';
import axios from 'axios';
import BuilderContext from '../BuilderContext'
import { Link } from 'react-router-dom';
import { BuilderConsumer } from '../BuilderContext'

class LatestLists extends Component {
  static contextType = BuilderContext    
  constructor(props) {
    super(props);
    this.state = {
        lists: [],
        admin: false,
        moderator: false,
        ad: '',
        ad_url: ''
    }
  }
  componentDidMount() {      
    axios.get('/api/latestlists')
    .then(response => {
        this.setState({lists: response.data})
    })
    axios.get('/api/latestad')
    .then(result => {
      this.setState({ad: result.data})
    })
    axios.get('/api/latestadurl')
    .then(result => {
      this.setState({ad_url: result.data})
    })
    axios.get('/auth/checkmoderator')
    .then(response => {
      this.setState({moderator: response.data.moderator})
    })
  }
  deleteList = (id) => {
    axios.post('/api/admindeletelist/' + id)    
    window.location.reload();
  }
  lists = () => {
    return this.state.lists.map(list => {
        return(
        <div key={list.id} className="list-list-item"><Link to={`/builder/${list.id}`}>{list.name}{!list.name && "Unnamed List" } ({list.faction_name})</Link>{this.state.moderator && <span className="delete" onClick={() => this.deleteList(list.id)}>DELETE</span>}</div>
        )
    })
}

  render() {
      return(
        <BuilderConsumer>
         {props => {
                    return(
                      <div>
                    <div style={{textAlign:'center', maxWidth: '100%'}}><a target="_blank" rel="noopener noreferrer" href={this.state.ad_url}><img style={{maxWidth: '100%'}} src={this.state.ad} /></a></div>
                        <div className="container">
                            
                          <div className="header">Latest Lists</div>
                          </div>
             {this.lists()}
             
        </div>
      )
                    }}
          </BuilderConsumer>
      )}
}
export default LatestLists;