import React, { Component } from 'react';
import BuilderContext from '../BuilderContext'

class BaseUnitList extends Component {
    static contextType = BuilderContext
  constructor(props) {
    super(props);
  }
  units(){
    return this.props.units.filter(unit => {
      return !unit.unit_fkey && unit.faction_fkey === parseInt(this.context.faction.id) && (unit.subfaction_fkey == this.context.subfaction.id || !this.context.subfaction.name) && unit.unit_type_fkey == this.context.unitType.id && (this.context.showCustom || !unit.user_fkey)
    })
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(unit => {
          return (
              <div key={unit.id} className="option-wrapper">
              <span className="option-unit-name" onClick={() => this.context.chooseBaseUnit(unit)} key={unit.id}>
                {unit.name}
              </span>
              </div>
              )
          }
    )
  }
  resetUnitType = () => {
    this.context.resetUnitType()
  }

  render() {
      return(
          <div>
          <div className="header">Choose Base Unit</div>
          <div className="back-wrapper"><span className="back" onClick={this.resetUnitType}> 🡨 Unit Types</span></div>
            {this.units()}
        </div>
      )
}
}
export default BaseUnitList;