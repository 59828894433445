import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Prompt } from "react-router-dom";
import { BuilderProvider } from '../BuilderContext'
import Collection from './collection.component'
import MyLists from './my-lists.component'
import BaseContext from '../BaseContext'
import { Link } from 'react-router-dom';
import axios from 'axios';

class MyProfile extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
      ad: '',
      ad_url:'',
      units: []
    }

}
componentDidMount(){  
  axios.get('/api/profilead')
  .then(result => {
    this.setState({ad: result.data})
  })
  axios.get('/api/profileadurl')
  .then(result => {
    this.setState({ad_url: result.data})
  })
  

}
render() {
    return (    
      <div>
      <div style={{textAlign:'center', maxWidth: '100%'}}><a target="_blank" rel="noopener noreferrer" href={this.state.ad_url}><img style={{maxWidth: '100%'}} src={this.state.ad} /></a></div>
      
      
        <div className="profile-wrapper">
          
        <MyLists />
        
            <Collection />
            </div> 
            </div> 
            )
            }
}

export default MyProfile;