import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class CreateSpecial extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        Description: '',
        Pilot: false,
        admin: false
      };
      axios.get('/auth/checkadmin')
      .then(res => {
          if(!res.data.admin){
            window.location = "/"
          } else{
            this.setState({admin: true})
          }
      })
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  handlePilotChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }
onSubmit = (e) => {
    e.preventDefault();       
    var special = {
        name: this.state.Name,
        description: this.state.Description,
        pilot: this.state.Pilot
    }
    axios.post('/api/special', special)
            .then(response => {
                this.setState({Name: '', Description: '', Pilot: false})
            })
}
  render() {
    return (    
        <div>  
          {this.state.admin &&
            <form onSubmit={this.onSubmit}>
            <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
            <BasicField label="Description" valueState={this.state.Description} onChangeState={this.handleChange} />   
            <div style={{textAlign: 'center'}}>
              <label>Pilot Ability?</label><input type="checkbox" placeholder="Pilot" name="Pilot" className="card-input" onChange={this.handlePilotChange} checked={this.state.pilot} />
             </div>
                           
            <div className="form-group">
                <input type="submit" value="Create Special" className="btn btn-primary" />
            </div>
            </form>    
          }
        </div>
    );
  }
}
export default CreateSpecial;