import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        factions: [],
        subfactions: [],
        units: [],
        weaponOptions: [],
        specialOptions: [],
        models: [],
        unitTypes: [],
        Faction: 1,
        UnitType: 1,
        Unit: null,
        Cost: 0,
        Figures: 1,
        Walk: 0,
        Run: 0,
        Jump: 0,
        Melee: 0,
        Model: null,
        Focus: 0,
        Pilot: 0,
        Defense: 0,
        Save: 0,
        HP: 0,
        TorsoHP: 0,
        LeftArmHP: 0,
        RightArmHP: 0,
        LegsHP: 0,
        Weapons: [],
        Specials: [],
        SortPriority: 1,
        Subfaction: null,
        wep: 'blank',
        image: '',
        spec: 'blank',
        admin: false,
        NamedPilot: false,
        BuyURL: '',
        admin: false,
        moderator: false,
        nonadmin: false,
        canedit: false,
        disabled: false
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
            axios.get('/api/units/' + this.props.match.params.id)
            .then(result => { 
            if(localStorage.user_id != result.data.user_fkey){
            window.location = "/"
            } else{
            this.setState({admin: res.data.admin, moderator: res.data.moderator, nonadmin: true, canedit: true})
            }
        })
    } else{
        this.setState({admin: res.data.admin, moderator: res.data.moderator, canedit: true})
      }
    })
  }
  
  componentDidMount() { 
    axios.get('/api/units/' + this.props.match.params.id)
    .then(result => {
        this.setState({Name: result.data.name, disabled: result.data.disabled, Model: result.data.model_fkey, BuyURL: result.data.buy_url, STLUrl: result.data.stl_url, Cost: result.data.cost, NamedPilot: result.data.named_pilot, Faction: result.data.faction_fkey, Unit: result.data.unit_fkey, Walk: result.data.walk_speed, Run: result.data.run_speed, Jump: result.data.jump, Melee: result.data.melee, Focus: result.data.focus, Pilot: result.data.pilot, Defense: result.data.defense, Save: result.data.save, HP: result.data.hp, TorsoHP: result.data.torso_hp, RightArmHP: result.data.right_arm_hp, LeftArmHP: result.data.left_arm_hp, LegsHP: result.data.legs_hp, UnitType: result.data.unit_type_fkey, Figures: result.data.figures, image: result.data.image, SortPriority: result.data.sort_priority, Subfaction: result.data.subfaction_fkey})
    })
    axios.get('/api/weapons/'+this.props.match.params.id)
    .then(result => {
        result.data.map(weapon => {
            this.setState({ Weapons: [...this.state.Weapons, {Weapon: weapon, Location: weapon.location}]}, () => console.log(this.state.Weapons))
        })
    })
    
    axios.get('/api/specials/'+this.props.match.params.id)
    .then(result => {
        result.data.map(special => {
            this.setState({ Specials: [...this.state.Specials, special]})
        })
    })
    axios.get('/api/factions')
    .then(result => {
        this.setState({factions: result.data})
    })
    axios.get('/api/subfactions')
    .then(result => {
        this.setState({subfactions: result.data})
    })
    axios.get('/api/userweapons')
    .then(result => {
        this.setState({weaponOptions: result.data})
    })
    axios.get('/api/specials')
    .then(result => {
        this.setState({specialOptions: result.data})
    })
    axios.get('/api/unittypes')
    .then(result => {
        this.setState({unitTypes: result.data})    })
    
    axios.get('/api/models')
    .then(result => {
        this.setState({models: result.data})
    })
    axios.get('/api/units')
    .then(result => {
        this.setState({units: result.data.sort((a, b) => a.name > b.name ? 1 : -1)})
    })
  }

  handleChange = (e) => {
      if(e.target.value === "----"){
        this.setState({ [e.target.name]: null });
      } else{
    this.setState({ [e.target.name]: e.target.value });
      }
  }
  
  addWeapon = (e) => {
    if(this.state.weaponOptions[e.target.value]){
          this.setState({ Weapons: [...this.state.Weapons, {Weapon: this.state.weaponOptions[e.target.value], Location: ''}]})
    }
}
  addSpecial = (e) => {
      console.log(e.target.value)
      if(this.state.specialOptions[e.target.value]){
            this.setState({ Specials: [...this.state.Specials, this.state.specialOptions[e.target.value]]})
      }
  }

onSubmit = (e) => {
    e.preventDefault();       
    var unit = {
        id: this.props.match.params.id,
        name: this.state.Name,
        cost: this.state.Cost,
        figures: this.state.Figures,
        faction_fkey: this.state.Faction,
        unit_fkey: this.state.Unit,
        unit_type_fkey: this.state.UnitType,
        walk_speed: this.state.Walk,
        run_speed: this.state.Run,
        jump: this.state.Jump,
        melee: this.state.Melee,
        focus: this.state.Focus,
        pilot: this.state.Pilot,
        defense: this.state.Defense,
        save: this.state.Save,
        hp: this.state.HP,
        torso_hp: this.state.TorsoHP,
        left_arm_hp: this.state.LeftArmHP,
        right_arm_hp: this.state.RightArmHP,
        model: this.state.Model,
        legs_hp: this.state.LegsHP,
        weapons: this.state.Weapons,
        specials: this.state.Specials,
        image: this.state.image,
        sort_priority: this.state.SortPriority,
        named_pilot: this.state.NamedPilot,
        buy_url: this.state.BuyURL,
        stl_url: this.state.STLUrl,
        subfaction: this.state.Subfaction,
        disabled: this.state.disabled
    }
    if(!this.state.nonadmin){
    axios.post('/api/updateunit', unit)
            .then(response => {
                if(response.data.name=="error"){
                    this.setState({error: "Error. Check variable types"})
                } else{
                    window.location = "/updateunit/"+this.props.match.params.id
                }
            })
    } else{
        axios.post('/api/updatecustomunit', unit)
        .then(response => {
            if(response.data.name=="error"){
                this.setState({error: "Error. Check variable types"})
            } else{
                window.location = "/updateunit/"+this.props.match.params.id
            }
        })
    }
}
chosenWeapons = () => {
        return this.state.Weapons.map((weapon, index) => {
            return(
                <div className="chosen-weapon-wrapper">
                <span onClick={() => this.removeWeapon(index)} className="chosen-special">{weapon.Weapon.name} X</span>
                <span> | Loc: </span>
                <input 
                type="text"
                className="form-control"
                onChange={(e) => this.updateLocation(e, index)}
                name="Loc"
                value={weapon.Location}
                />
                </div>
            )
        })
    }
updateLocation = (e, index) => {
    var arr = this.state.Weapons
    arr[index].Location = e.target.value
    this.setState({Weapons: arr})
}


chosenSpecials = () => {
    return this.state.Specials.map((special, index) => {
        return(
            <span className="chosen-special" onClick={() => this.removeSpecial(index)}>{special.name} X</span>
        )
    })
}
removeWeapon = (index) => {
    var newArray = [...this.state.Weapons]
    newArray.splice(index, 1)
    this.setState({Weapons: newArray})
}

removeSpecial = (index) => {
    var newArray = [...this.state.Specials]
    newArray.splice(index, 1)
    this.setState({Specials: newArray})
}
toggleNamedPilot = () => {
    this.setState({NamedPilot: !this.state.NamedPilot})
}
toggleDisabled = () => {
    this.setState({disabled: !this.state.disabled})
}

uploadImage = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append('file', files[0])
    data.append('upload_preset', 'card_art')
    const res = await fetch(
        'https://api.cloudinary.com/v1_1/dd1mrevav/image/upload',
        {
            method: 'POST',
            body: data
        }
    )
    const file = await res.json()    
    this.setState({image: file.secure_url});
    
}
  render() {
    return (    
        <div>  
            {this.state.canedit &&
        <form onSubmit={this.onSubmit}>
        <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
        <div className="form-group">
            <label>Unit Type</label>
            <select value={this.state.UnitType} name="UnitType" onChange={this.handleChange}>
                <option value={null}>----</option>
                {this.state.unitTypes.map(unitType => {
                    return(
                        <option value={unitType.id}>{unitType.name}</option>
                    )
                })}
            </select>
        </div>
        
        <div className="form-group">
            <label>Model</label>
            <select value={this.state.Model} name="Model" onChange={this.handleChange}>
                <option value={null}>----</option>
                {this.state.models.map(model => {
                    return(
                        <option value={model.id}>{model.name}</option>
                    )
                })}
            </select>
        </div>
        {this.state.UnitType == 3  &&
        <BasicField label="Figures" valueState={this.state.Figures} onChangeState={this.handleChange} />  
        }
        <BasicField label="Cost" valueState={this.state.Cost} onChangeState={this.handleChange} />  
        <BasicField label="Walk" valueState={this.state.Walk} onChangeState={this.handleChange} />  
        <BasicField label="Run" valueState={this.state.Run} onChangeState={this.handleChange} />  
        <BasicField label="Jump" valueState={this.state.Jump} onChangeState={this.handleChange} />  
        <BasicField label="Melee" valueState={this.state.Melee} onChangeState={this.handleChange} />  
        <BasicField label="Focus" valueState={this.state.Focus} onChangeState={this.handleChange} />  
        {this.state.UnitType == 1 &&
        <BasicField label="Pilot" valueState={this.state.Pilot} onChangeState={this.handleChange} />  
        }
        <BasicField label="Defense" valueState={this.state.Defense} onChangeState={this.handleChange} />  
        <BasicField label="Save" valueState={this.state.Save} onChangeState={this.handleChange} />  
        {this.state.UnitType != 1  &&
        <BasicField label="HP" valueState={this.state.HP} onChangeState={this.handleChange} />  
        }        
        {this.state.UnitType == 1  &&
        <BasicField label="TorsoHP" valueState={this.state.TorsoHP} onChangeState={this.handleChange} />  
        }
        {this.state.UnitType == 1  &&
        <BasicField label="LeftArmHP" valueState={this.state.LeftArmHP} onChangeState={this.handleChange} />  
    }
    {this.state.UnitType == 1  &&
        <BasicField label="RightArmHP" valueState={this.state.RightArmHP} onChangeState={this.handleChange} />  
    }
    {this.state.UnitType == 1  &&
        <BasicField label="LegsHP" valueState={this.state.LegsHP} onChangeState={this.handleChange} />  
    }
        {this.state.moderator &&
        <BasicField label="SortPriority" valueState={this.state.SortPriority} onChangeState={this.handleChange} />  
        } 
        {this.state.moderator &&
        <BasicField label="BuyURL" valueState={this.state.BuyURL} onChangeState={this.handleChange} />  
        }
        {this.state.moderator &&
        <BasicField label="STLUrl" valueState={this.state.STLUrl} onChangeState={this.handleChange} />  
        }
        
        <div className="form-group">
            <label>Faction</label>
            <select value={this.state.Faction} name="Faction" onChange={this.handleChange}>
                {this.state.factions.map(faction => {
                    return(
                        <option value={faction.id}>{faction.name}</option>
                    )
                })}
            </select>
        </div>
        
        <div className="form-group">
        <label>Subfaction</label>
            <select value={this.state.Subfaction} name="Subfaction" onChange={this.handleChange}>
                <option value=''>-----</option>
                {this.state.subfactions.filter(f => f.faction_fkey == this.state.Faction).map(subfaction => {
                    return(
                        <option value={subfaction.id}>{subfaction.name}</option>
                    )
                })}
            </select>
        </div>
        
        <div className="form-group">
            <label>Base Unit (Optional)</label>
            <select value={this.state.Unit} name="Unit" onChange={this.handleChange}>
                <option value={null}>----</option>
                {this.state.units.map(unit => {
                    return(
                        <option value={unit.id}>{unit.name}</option>
                    )
                })}
            </select>
        </div>
        <div className="form-group">
            <label>Weapons</label>
            <select value={this.state.wep} name="Weapon" onChange={this.addWeapon}>
                <option value="blank">----</option>
                {this.state.weaponOptions.map((weapon, index) => {
                    return(
                        <option value={index}>{weapon.name}</option>
                    )
                })}
            </select>
        </div>
    <div>{this.chosenWeapons()}</div>
                
        <div className="form-group">
            <label>Specials</label>
            <select value="blank" name="Special" onChange={this.addSpecial}>
                <option value={null}>----</option>
                {this.state.specialOptions.map((special, index) => {
                    return(
                        <option value={index}>{special.name}</option>
                    )
                })}
            </select>
        </div>
        
        
    <div className="chosen-specials">{this.chosenSpecials()}</div>
        <div className="error">
            {this.state.error}
        </div><br /><br />
    <div style={{textAlign: 'center'}}>
        <label>
          Named Pilot? 
          <input
            name="NamedPilot"
            type="checkbox"
            checked={this.state.NamedPilot}
            onChange={this.toggleNamedPilot} />
        </label>
    </div>
    
    <div style={{textAlign: 'center'}}>
        <label>
          Disable this unit? 
          <input
            name="disabled"
            type="checkbox"
            checked={this.state.disabled}
            onChange={this.toggleDisabled} />
        </label>
    </div>
        <br /><br />
        <div style={{textAlign:'center'}}>
            <label>Unit Image </label> 
        <input type="file"
                    name="file"
                    placeholder="Upload an image"
                    onChange={this.uploadImage}
                /><br />
            <img src={this.state.image} /><br /><br />
            </div>
        <div style={{textAlign:'center'}}>
            <input style = {{cursor: 'pointer'}} type="submit" value="Update Unit" className="btn btn-primary" />
        </div><br /><br />
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateUnit;