import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateSubfaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        Name: '',
        error: '',
        Faction: null,
        factions: []
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
  }
  
  componentDidMount() { 
    axios.get('/api/subfaction/' + this.props.match.params.id)
    .then(result => {
        this.setState({Name: result.data.name, Faction: result.data.faction_fkey})
    })
    axios.get('/api/factions')
    .then(result => {
        this.setState({factions: result.data})
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

onSubmit = (e) => {
    e.preventDefault();       
    var subfaction = {
        id: this.props.match.params.id,
        name: this.state.Name,
        faction: this.state.Faction
    }
    axios.post('/api/updatesubfaction', subfaction)
            .then(response => {
                if(response.data.name=="error"){
                    this.setState({error: "Error. Check variable types"})
                } else{
                  window.location.reload();
                }
            })
}

  render() {
    return (    
        <div>  
            {this.state.moderator &&
        <form onSubmit={this.onSubmit}>
        <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
        <div style={{textAlign:'center'}}>
            <div className="form-group">
            <label>Faction</label>
            <select value={this.state.Faction} name="Faction" onChange={this.handleChange}>
                {this.state.factions.map(faction => {
                    return(
                        <option value={faction.id}>{faction.name}</option>
                    )
                })}
            </select>
            </div><br /><br />
            </div>
        <div className="error">
            {this.state.error}
        </div>
        <div className="form-group">
            <input type="submit" value="Update Subfaction" className="btn btn-primary" />
        </div>
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateSubfaction;