import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class CreateModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        admin: false
      };
      axios.get('/auth/checkadmin')
      .then(res => {
          if(!res.data.admin){
            window.location = "/"
          } else{
            this.setState({admin: true})
          }
      })
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
onSubmit = (e) => {
    e.preventDefault();       
    var model = {
        name: this.state.Name,
    }
    axios.post('/api/model', model)
            .then(response => {
                this.setState({Name: ''})
            })
}
  render() {
    return (    
        <div>  
          {this.state.admin &&
            <form onSubmit={this.onSubmit}>
            <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />          
            <div className="form-group">
                <input type="submit" value="Create Model" className="btn btn-primary" />
            </div>
            </form>    
          }
        </div>
    );
  }
}
export default CreateModel;