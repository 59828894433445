import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        Username: '',
        userModerator: false
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
  }
  
  componentDidMount() { 
    axios.get('/api/user/' + this.props.match.params.id)
    .then(result => {
        this.setState({Username: result.data.username, userModerator: result.data.moderator})
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

onSubmit = (e) => {
    e.preventDefault();       
    var user = {
        id: this.props.match.params.id,
        moderator: this.state.userModerator
    }
    axios.post('/api/updateuser', user)
            .then(response => {
                if(response.data.name=="error"){
                    this.setState({error: "Error."})
                } else{
                  window.location.reload();
                }
            })
}

toggleModerator = () => {
    this.setState({userModerator: !this.state.userModerator})
}
  render() {
    return (    
        <div>  
            {this.state.admin &&
        <form onSubmit={this.onSubmit}>
            <div>{this.state.Username}</div>
            <div style={{textAlign: 'center'}}>
        <label>
          Moderator?
          <input
            name="userModerator"
            type="checkbox"
            checked={this.state.userModerator}
            onChange={this.toggleModerator} />
        </label>
    </div>
        <div className="form-group">
            <input type="submit" value="Update Special" className="btn btn-primary" />
        </div>
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateUser;