import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UploadUnitImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedFile: null,
        loaded: 0,
        admin: false,
        moderator: false
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
  }
  onClickHandler = () => {
    const data = new FormData() 
    data.append('file', this.state.selectedFile)
    console.log(data)
    axios.post("/api/uploadimage", data)
      .then(res => { // then print response status
        console.log(res)
      })
      .catch(err => { // then print response status
        console.log(err)
      })
    }
onChangeHandler=event=>{
    this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
      })

}

uploadImage = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append('file', files[0])
    data.append('upload_preset', 'card_art')
    const res = await fetch(
        'https://api.cloudinary.com/v1_1/dd1mrevav/image/upload',
        {
            method: 'POST',
            body: data
        }
    )
    const file = await res.json()    
    this.setState({image: file.secure_url});
    
}

  render() {
    return (    
        <div>  
          {this.state.moderator &&
          <div>
               <input type="file"
                    name="file"
                    placeholder="Upload a (preferrably square) image"
                    onChange={this.uploadImage}
                />
            <button type="button" class="btn btn-success btn-block" onClick={this.uploadImage}>Upload</button>
            </div>
          }
        </div>
    );
  }
}
export default UploadUnitImage;