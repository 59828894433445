import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Title: '',
        Body: '',
        admin: false,
        moderator: false
      };
      axios.get('/auth/checkmoderator')
      .then(res => {
          if(!res.data.admin && !res.data.moderator){
            window.location = "/"
          } else{
            this.setState({admin: res.data.admin, moderator: res.data.moderator})
          }
      })
  }
  
  componentDidMount() { 
    axios.get('/api/update/' + this.props.match.params.id)
    .then(result => {
        this.setState({Title: result.data.title, Body: result.data.body})
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

onSubmit = (e) => {
    e.preventDefault();       
    var update = {
        id: this.props.match.params.id,
        title: this.state.Title,
        body: this.state.Body
    }
    axios.post('/api/updateupdate', update)
            .then(response => {
                window.location="/updateupdate/"+this.props.match.params.id
            })
}
  render() {
    return (    
        <div>  
            {this.state.moderator &&
        <form onSubmit={this.onSubmit}>
        <BasicField label="Title" valueState={this.state.Title} onChangeState={this.handleChange} />  
        <div className="form-group">
            <label>Body</label>
            <textarea 
            type="text"
            className="form-control"
            value={this.state.Body}
            onChange={this.handleChange}
            name="Body"
            style={{height: 100}}
            />
        </div> 
        <div className="error">
            {this.state.error}
        </div>
        <div className="form-group">
            <input type="submit" value="Update Update" className="btn btn-primary" />
        </div>
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateUpdate;