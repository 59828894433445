import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateAds extends Component {
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        updates_ad: '',
        profile_ad: '',
        builder_ad: '',
        latest_ad: '',
        updates_ad_url: '',
        profile_ad_url: '',
        builder_ad_url: '',
        latest_ad_url: '',
        error: ''
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
  }
  
  componentDidMount() { 
    axios.get('/api/updatesad')
    .then(result => {
        this.setState({updates_ad: result.data})
    })
    axios.get('/api/builderad')
    .then(result => {
        this.setState({builder_ad: result.data})
    })
    axios.get('/api/latestad')
    .then(result => {
        this.setState({latest_ad: result.data})
    })
    axios.get('/api/profilead')
    .then(result => {
        this.setState({profile_ad: result.data})
    })

    
    axios.get('/api/updatesadurl')
    .then(result => {
        this.setState({updates_ad_url: result.data})
    })
    axios.get('/api/builderadurl')
    .then(result => {
        this.setState({builder_ad_url: result.data})
    })
    axios.get('/api/latestadurl')
    .then(result => {
        this.setState({latest_ad_url: result.data})
    })
    axios.get('/api/profileadurl')
    .then(result => {
        this.setState({profile_ad_url: result.data})
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

onSubmit = (e) => {
    e.preventDefault();       
    var ads = {
        builder: this.state.builder_ad,
        profile: this.state.profile_ad,
        news: this.state.updates_ad,
        latest: this.state.latest_ad,
        builder_url: this.state.builder_ad_url,
        profile_url: this.state.profile_ad_url,
        news_url: this.state.updates_ad_url,
        latest_url: this.state.latest_ad_url
    }
    axios.post('/api/updateads', ads)
            .then(response => {
                if(response.data.name=="error"){
                    this.setState({error: "Error. Check variable types"})
                } else{
                  window.location.reload();
                }
            })
}
uploadBuilderAd = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append('file', files[0])
    data.append('upload_preset', 'revelations_ads')
    const res = await fetch(
        'https://api.cloudinary.com/v1_1/dd1mrevav/image/upload',
        {
            method: 'POST',
            body: data
        }
    )
    const file = await res.json()    
    this.setState({builder_ad: file.secure_url});
    
  }
  uploadLatestAd = async e => {
      const files = e.target.files
      const data = new FormData()
      data.append('file', files[0])
      data.append('upload_preset', 'revelations_ads')
      const res = await fetch(
          'https://api.cloudinary.com/v1_1/dd1mrevav/image/upload',
          {
              method: 'POST',
              body: data
          }
      )
      const file = await res.json()    
      this.setState({latest_ad: file.secure_url});
      
    }

    
  uploadProfileAd = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append('file', files[0])
    data.append('upload_preset', 'revelations_ads')
    const res = await fetch(
        'https://api.cloudinary.com/v1_1/dd1mrevav/image/upload',
        {
            method: 'POST',
            body: data
        }
    )
    const file = await res.json()    
    this.setState({profile_ad: file.secure_url});
    
  }

  
  uploadUpdatesAd = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append('file', files[0])
    data.append('upload_preset', 'revelations_ads')
    const res = await fetch(
        'https://api.cloudinary.com/v1_1/dd1mrevav/image/upload',
        {
            method: 'POST',
            body: data
        }
    )
    const file = await res.json()    
    this.setState({updates_ad: file.secure_url});
    
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    return (    
        <div>  
            {this.state.moderator &&
        <form onSubmit={this.onSubmit}>
       <div style={{textAlign:'center'}}>
            <label>Builder Ad </label> 
        <input type="file"
                    name="file"
                    placeholder="Upload an image"
                    onChange={this.uploadBuilderAd}
                /><br />
            <img src={this.state.builder_ad} /><br /><br />
            </div>  

            <BasicField label="builder_ad_url" valueState={this.state.builder_ad_url} onChangeState={this.handleChange} />  
            <div style={{textAlign:'center'}}>
            <label>Latest Lists Ad </label> 
        <input type="file"
                    name="file"
                    placeholder="Upload an image"
                    onChange={this.uploadLatestAd}
                /><br />
            <img src={this.state.latest_ad} /><br /><br />
            </div>  

            <BasicField label="latest_ad_url" valueState={this.state.latest_ad_url} onChangeState={this.handleChange} />  
            <div style={{textAlign:'center'}}>
            <label>Profile Ad </label> 
        <input type="file"
                    name="file"
                    placeholder="Upload an image"
                    onChange={this.uploadProfileAd}
                /><br />
            <img src={this.state.profile_ad} /><br /><br />
            </div>  
            <BasicField label="profile_ad_url" valueState={this.state.profile_ad_url} onChangeState={this.handleChange} />  

            <div style={{textAlign:'center'}}>
            <label>Home Page Ad </label> 
        <input type="file"
                    name="file"
                    placeholder="Upload an image"
                    onChange={this.uploadUpdatesAd}
                /><br />
            <img src={this.state.updates_ad} /><br /><br />
            </div>  
            <BasicField label="updates_ad_url" valueState={this.state.updates_ad_url} onChangeState={this.handleChange} />  
        <div className="error">
            {this.state.error}
        </div>
        <div className="form-group">
            <input type="submit" value="Update Ads" className="btn btn-primary" />
        </div>
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateAds;