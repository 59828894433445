import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        Description: '',
        admin: false,
        Faction: null,
        Subfaction: null,
        factions: [],
        subfactions: []
      };
      axios.get('/auth/checkadmin')
      .then(res => {
          if(!res.data.admin){
            window.location = "/"
          } else{
            this.setState({admin: true})
          }
      })
  }
  componentDidMount(){    
    axios.get('/api/factions')
    .then(result => {
        this.setState({factions: result.data})
    })
    axios.get('/api/subfactions')
    .then(result => {
        this.setState({subfactions: result.data})
    })
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
onSubmit = (e) => {
    e.preventDefault();       
    var order = {
        name: this.state.Name,
        description: this.state.Description,
        faction: this.state.Faction,
        subfaction: this.state.Subfaction,
    }
    axios.post('/api/order', order)
            .then(response => {
                this.setState({Name: '', Description: ''})
            })
}
  render() {
    return (    
        <div>  
          {this.state.admin &&
            <form onSubmit={this.onSubmit}>
            <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
            <BasicField label="Description" valueState={this.state.Description} onChangeState={this.handleChange} />       
            
        <div className="form-group">
            <label>Faction</label>
            <select value={this.state.Faction} name="Faction" onChange={this.handleChange}>
              <option value={null}>----</option>
                {this.state.factions.map(faction => {
                    return(
                        <option value={faction.id}>{faction.name}</option>
                    )
                })}
            </select>

            
            <label>Subfaction</label>
            <select value={this.state.Subfaction} name="Subfaction" onChange={this.handleChange}>
              <option value={null}>----</option>
                {this.state.subfactions.filter(f => f.faction_fkey == this.state.Faction).map(subfaction => {
                    return(
                        <option value={subfaction.id}>{subfaction.name}</option>
                    )
                })}
            </select>
        </div>     
            <div className="form-group">
                <input type="submit" value="Create Order" className="btn btn-primary" />
            </div>
            </form>    
          }
        </div>
    );
  }
}
export default CreateOrder;