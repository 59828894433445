import React, { Component } from 'react';
import axios from 'axios';
import BuilderContext from '../BuilderContext'
import { BuilderConsumer } from '../BuilderContext'

class OrderList extends Component {
    static contextType = BuilderContext
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {      
  }
  orders(){
    return this.props.orders.map(order => {
      if(this.context.chosenOrders.filter(o => {
        return o.id == order.id
      }).length < 1)
      {
          return (
              <div key={order.id} className="option-wrapper">
              <span className="option-unit-name" onClick={() => this.context.showOrder(order)} key={order.id}>
                {order.name}
              </span>
              <span className="add-button" onClick={() => this.context.addOrder(order)}>+</span>
              </div>
              )
          }
        }
    )
  }
  render() {
      return(
          <div>
          <div className="header">Choose Order Cards</div>
            {this.orders()}
        </div>
      )
}
}
export default OrderList;