import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class CreateWeapon extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        Range: 0,
        RoF: 0,
        Damage: 0,
        SpecialText: '',
        Ammo: 0,
        admin: false
      };
      axios.get('/auth/checkadmin')
      .then(res => {
          if(!res.data.admin){
            window.location = "/"
          } else{
            this.setState({admin: true})
          }
      })
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
onSubmit = (e) => {
    e.preventDefault();       
    var weapon = {
        name: this.state.Name,
        range: this.state.Range,
        rate_of_fire: this.state.RoF,
        damage: this.state.Damage,
        special_text: this.state.SpecialText,
        ammo: this.state.Ammo
    }
    axios.post('/api/weapon', weapon)    
      .then(response => {
        if(response.data.name=="error"){
          this.setState({error: "Error. Check variable types"})
      } else{
        this.setState({Name: '', Range: 0, RoF: 0, Damage: 0, SpecialText: '', Ammo: 0});
      }
    })
}
  render() {
    return (    
        <div>  
          {this.state.admin &&
            <form onSubmit={this.onSubmit}>
            <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
            <BasicField label="Range" valueState={this.state.Range} onChangeState={this.handleChange} />     
            <BasicField label="RoF" valueState={this.state.RoF} onChangeState={this.handleChange} />   
            <BasicField label="Damage" valueState={this.state.Damage} onChangeState={this.handleChange} />   
            <BasicField label="SpecialText" valueState={this.state.SpecialText} onChangeState={this.handleChange} />   
            <BasicField label="Ammo" valueState={this.state.Ammo} onChangeState={this.handleChange} />          
            <div className="form-group">
                <input type="submit" value="Create Weapon" className="btn btn-primary" />
            </div>
            </form>    
        }
        <div className="error">
            {this.state.error}
        </div>
        </div>
    );
  }
}
export default CreateWeapon;