import React, { Component } from 'react';
import ListBuilder from './components/list-builder.component'
import CreateUnit from './components/create-unit.component'
import CreateSpecial from './components/create-special.component'
import CreateOrder from './components/create-order.component'
import CreateWeapon from './components/create-weapon.component'
import CreateFaction from './components/create-faction.component'
import CreateSubfaction from './components/create-subfaction.component'
import CreateUpdate from './components/create-update.component'
import CreateUnitType from './components/create-unit-type.component'
import CreateModel from './components/create-model.component'
import CreateCustomUnit from './components/create-custom-unit.component'
import UpdateUnit from './components/update-unit.component'
import UpdateCustomUnit from './components/update-custom-unit.component'
import Navbar from './components/navbar.component'
import News from './components/news.component'
import MyProfile from './components/my-profile.component'
import Login from './components/login.component'
import LatestLists from './components/latest-lists.component'
import PasswordReset from './components/password-reset.component'
import ForgotPassword from './components/forgot-password.component'
import AdminPanel from './components/admin-panel.component'
import EditUnitsList from './components/edit-units-list.component'
import EditSpecialsList from './components/edit-specials-list.component'
import EditOrdersList from './components/edit-orders-list.component'
import EditWeaponsList from './components/edit-weapons-list.component'
import EditFactionsList from './components/edit-factions-list.component'
import EditSubfactionsList from './components/edit-subfactions-list.component'
import UpdateSpecial from './components/update-special.component'
import UpdateModel from './components/update-model.component'
import UpdateFaction from './components/update-faction.component'
import UpdateSubfaction from './components/update-subfaction.component'
import UpdateOrder from './components/update-order.component'
import UploadUnitImage from './components/upload-unit-image.component'
import UpdateWeapon from './components/update-weapon.component'
import UpdateUpdate from './components/update-update.component'
import UpdateUser from './components/update-user.component'
import UpdateAds from './components/update-ads.component'
import UpdateVersion from './components/update-version.component'
import EditUpdatesList from './components/edit-updates-list.component'
import UpdatePoints from './components/update-points.component'
import EditUsersList from './components/edit-users-list.component'
import axios from 'axios';
import { BaseProvider } from './BaseContext'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './styles/global.css';
import EditModelsList from './components/edit-models-list.component';

export default class App extends Component {
  constructor(props) {    
    super(props);
    this.state={
      logout: this.logout
    }
  }
  logout(){
    localStorage.removeItem('user_id')
    axios.get('/auth/logout')
    .then(result => window.location="/")
  }
  render(){
  return (
    <div>
    <BaseProvider value={this.state}>
    <Router>
    <Navbar />
    <Route exact path="/" component={News} />
    <Route path="/builder/:id?" exact component={ListBuilder} />
    <Route path="/addunit/:unit?" component={CreateUnit} />
    <Route path="/addspecial" component={CreateSpecial} />
    <Route path="/addorder" component={CreateOrder} />
    <Route path="/addweapon" component={CreateWeapon} />
    <Route path="/addfaction" component={CreateFaction} />
    <Route path="/addsubfaction" component={CreateSubfaction} />
    <Route path="/addunittype" component={CreateUnitType} />
    <Route path="/addmodel" component={CreateModel} />
    <Route path="/addupdate" component={CreateUpdate} />
    <Route path="/addcustomunit" component={CreateCustomUnit} />
    <Route path="/updateunit/:id" component={UpdateUnit} />
    <Route path="/updatecustomunit/:id" component={UpdateCustomUnit} />
    <Route path="/updatespecial/:id" component={UpdateSpecial} />
    <Route path="/updatemodel/:id" component={UpdateModel} />
    <Route path="/updateorder/:id" component={UpdateOrder} />
    <Route path="/updatefaction/:id" component={UpdateFaction} />
    <Route path="/updatesubfaction/:id" component={UpdateSubfaction} />
    <Route path="/updateweapon/:id" component={UpdateWeapon} />
    <Route path="/updateupdate/:id" component={UpdateUpdate} />
    <Route path="/updateuser/:id" component={UpdateUser} />
    <Route path="/updateversion" component={UpdateVersion} />
    <Route path="/updatepoints" component={UpdatePoints} />
    <Route path="/updateads" component={UpdateAds} />
    <Route path="/profile" component={MyProfile} />
    <Route path="/latest" component={LatestLists} />
    <Route path="/signin" component={Login} />
    <Route path="/resetpassword/:code" component={PasswordReset} />
    <Route path="/forgotpassword" component={ForgotPassword} />
    <Route path="/admin" component={AdminPanel} />
    <Route path="/editunits" component={EditUnitsList} />
    <Route path="/editmodels" component={EditModelsList} />
    <Route path="/editspecials" component={EditSpecialsList} />
    <Route path="/editfactions" component={EditFactionsList} />
    <Route path="/editsubfactions" component={EditSubfactionsList} />
    <Route path="/editorders" component={EditOrdersList} />
    <Route path="/editupdates" component={EditUpdatesList} />
    <Route path="/editweapons" component={EditWeaponsList} />
    <Route path="/editusers" component={EditUsersList} />
    <Route path="/uploadimage" component={UploadUnitImage} />
    </Router>
    </BaseProvider>
    </div>
  );
}}
