import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateSpecial extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        Description: '',
        Pilot: false,
        error: '',
        admin: false,
        moderator: false
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
  }
  
  componentDidMount() { 
    axios.get('/api/special/' + this.props.match.params.id)
    .then(result => {
        this.setState({Name: result.data.name, Description: result.data.description, Pilot: result.data.pilot})
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  handlePilotChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

onSubmit = (e) => {
    e.preventDefault();       
    var special = {
        id: this.props.match.params.id,
        name: this.state.Name,
        description: this.state.Description,
        pilot: this.state.Pilot
    }
    axios.post('/api/updatespecial', special)
            .then(response => {
                if(response.data.name=="error"){
                    this.setState({error: "Error. Check variable types"})
                } else{
                  window.location.reload();
                }
            })
}
chosenWeapons = () => {
        return this.state.Weapons.map((weapon, index) => {
            return(
                <div>
                <span onClick={() => this.removeWeapon(index)}>{weapon.Weapon.name}</span>
                <span> | Loc: </span>
                <input 
                type="text"
                className="form-control"
                onChange={(e) => this.updateLocation(e, index)}
                name="Loc"
                value={weapon.Location}
                />
                </div>
            )
        })
    }

  render() {
    return (    
        <div>  
            {this.state.moderator &&
        <form onSubmit={this.onSubmit}>
        <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
        <div className="form-group">
            <label>Description</label>
            <textarea 
            type="text"
            className="form-control"
            value={this.state.Description}
            onChange={this.handleChange}
            name="Description"
            style={{height: 100}}
            />
        </div> 
        
        <div style={{textAlign: 'center'}}>
              <label>Pilot Ability?</label><input type="checkbox" placeholder="Pilot" name="Pilot" className="card-input" onChange={this.handlePilotChange} checked={this.state.Pilot} />
             </div>
        <div className="error">
            {this.state.error}
        </div>
        <div className="form-group">
            <input type="submit" value="Update Special" className="btn btn-primary" />
        </div>
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateSpecial;