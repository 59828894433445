import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class CreateFaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        admin: false,
        image: ''
      };
      axios.get('/auth/checkadmin')
      .then(res => {
          if(!res.data.admin){
            window.location = "/"
          } else{
            this.setState({admin: true})
          }
      })
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
onSubmit = (e) => {
    e.preventDefault();       
    var faction = {
        name: this.state.Name,
        image: this.state.image
    }
    axios.post('/api/faction', faction)
            .then(response => {
                this.setState({Name: ''})
            })
}
uploadImage = async e => {
  const files = e.target.files
  const data = new FormData()
  data.append('file', files[0])
  data.append('upload_preset', 'card_art')
  const res = await fetch(
      'https://api.cloudinary.com/v1_1/dd1mrevav/image/upload',
      {
          method: 'POST',
          body: data
      }
  )
  const file = await res.json()    
  this.setState({image: file.secure_url});
  
}
  render() {
    return (    
        <div>  
          {this.state.admin &&
            <form onSubmit={this.onSubmit}>
            <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />             
            <div className="form-group">
                <input type="submit" value="Create Faction" className="btn btn-primary" />
            </div> 
            <div style={{textAlign:'center'}}>
            <label>Faction Image </label> 
        <input type="file"
                    name="file"
                    placeholder="Upload an image"
                    onChange={this.uploadImage}
                /><br />
            <img src={this.state.image} /><br /><br />
            </div>
            </form>    
          }
        </div>
    );
  }
}
export default CreateFaction;