
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseContext from '../BaseContext'
import { BaseConsumer } from '../BaseContext'
import axios from 'axios';
import '../styles/navbar.css';

class EditUpdatesList extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        updates: []
    }    
    axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
    axios.get('/api/updates')
    .then(res => {
        this.setState({updates: res.data})
    })
  }
  units = () => {
      return this.state.updates.map((update) => {
          return(
              <div><Link to={`/updateupdate/${update.id}`}>{update.title}</Link></div>
          )
      })
  }
  render() {
    return (    
        
        <BaseConsumer>
         {props => {
                    return(
        <div>  
        {this.state.moderator &&
        <div>
            {this.units()}
           </div>
        }
        </div>
                    )}}
        </BaseConsumer>
    );
  }
}
export default EditUpdatesList;

