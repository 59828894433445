import React, { Component } from 'react';
import axios from 'axios';
import BuilderContext from '../BuilderContext'
import { BuilderConsumer } from '../BuilderContext'

class Army extends Component {
  static contextType = BuilderContext    
  constructor(props) {
    super(props);
  }
  componentDidMount() {      
    
  }
  units = () =>{
    return this.context.army.map((unit, index) => {
            return (
              <div key={index} className="list-unit-wrapper">
                <span className="add-button" onClick={() => this.context.removeUnit(index)}>-</span>
                <span className="option-unit-name" onClick={() => this.context.showUnit(unit)}>
                  {unit.name} [{unit.cost}]
                </span>
                </div>
                )
    })
    }

  render() {
      return(
        <BuilderConsumer>
         {props => {
                    return(
          <div>
            {this.units()}
        </div>
      )
                    }}
          </BuilderConsumer>
      )}
}
export default Army;