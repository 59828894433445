import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/login.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
        username: '',
        password: '',
        email: '',
        error: '',
        signup: false
    }
  }
  login = (event) => {
      event.preventDefault();
      const user = {
          username: this.state.username,
          password: this.state.password
      }
    axios.post('/auth/login', {
        withCredentials: true,
        crossDomain: true,
        data: user
    },
    user)
    .then(result => {
        this.setState({error: result.data.message})
        if(result.data.username){
            localStorage.user_id = result.data.id
            window.location="/builder"
        }
    })      
  }
  signup = (event) => {
      event.preventDefault();
      const user = {
          username: this.state.username,
          password: this.state.password,
          email: this.state.email
      }
    axios.post('/auth/signup', {
        withCredentials: true,
        crossDomain: true,
        data: user
    },
    user)
    .then(result => {
        if(result.data.username){
            const user = {
            username: this.state.username,
            password: this.state.password
            }
            axios.post('/auth/login', {
                withCredentials: true,
                crossDomain: true,
                data: user
            },
            user)
            .then(r => {
                if(r.data.username){
                    localStorage.user_id = r.data.id
                    window.location="/builder"
                }
            })      
        } else{
            console.log(result)
            this.setState({error: result.data.message})
        }
    })      
  }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    toggleSignup = () => {
        this.setState({signup: !this.state.signup})
    }

  render() {
    return (    
        <div className="login">  
            <div className="login-form">
                <form class="form" onSubmit={this.state.signup ? this.signup : this.login}>
                    <input value={this.state.username} name="username" onChange={this.handleChange} type="text" id="username" placeholder="username" required /><br/>
                    <input value={this.state.password} name="password" onChange={this.handleChange} type="password" id="password" placeholder="pw" required />  <br/>
                    {this.state.signup &&
                    <div><input value={this.state.email} name="email" type="email" onChange={this.handleChange} id="email" placeholder="email" required /></div>
                    }
                    <button type="submit">{this.state.signup ? 'Sign Up' : 'Login'}</button>              
                </form>
            </div>
            {this.state.signup &&
            <div className="login-footer">Already have an account? <span className="login-link" onClick={this.toggleSignup}>Log In</span></div>
            }
            {!this.state.signup &&
            <div className="login-footer">Need an account? <span className="login-link" onClick={this.toggleSignup}>Sign Up</span></div>
            }
            {!this.state.signup &&
            <div className="login-footer"><Link to="/forgotpassword">Forgot Password?</Link></div>
            }
            <div className="error">{this.state.error}</div>
        </div>
    );
  }
}
export default Login;