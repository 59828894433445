
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseContext from '../BaseContext'
import { BaseConsumer } from '../BaseContext'
import axios from 'axios';
import '../styles/navbar.css';

class EditUnitsList extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        units: [],
        deleting: -1
    }    
    axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
    axios.get('/api/adminunits')
    .then(res => {
        this.setState({units: res.data})
    })
  }
  confirmDelete = (unit) => {
    this.setState({deleting: unit})
  }
  delete = () => {
    axios.post('/api/unitdelete', {id: this.state.deleting})
      .then(result => {
            window.location = "/editunits"
      })
  }
  units = () => {
      return this.state.units.map((unit) => {
          return(
          <div className="list-list-item"><Link to={`/updateunit/${unit.id}`}>{unit.name}</Link> | {this.state.admin && <Link to={`/addunit/${unit.id}`}>Duplicate</Link>} {this.state.admin && <span className="delete" onClick={() => this.confirmDelete(unit.id)}>DELETE</span>}</div>
          )
      })
  }
  render() {
    return (    
        
        <BaseConsumer>
         {props => {
                    return(
        <div>  
        {this.state.moderator &&
        <div>
            {this.state.deleting != -1 &&
              <div className="confirm-delete">ARE YOU SURE?
                <div className="cancel" onClick={this.cancelDelete}>cancel</div>
                <div className="confirm" onClick={this.delete}>CONFIRM</div>
              </div>
            }
            {this.units()}
           </div>
        }
        </div>
                    )}}
        </BaseConsumer>
    );
  }
}
export default EditUnitsList;

