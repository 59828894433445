import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseContext from '../BaseContext'
import { BaseConsumer } from '../BaseContext'
import axios from 'axios';
import '../styles/navbar.css';

class AdminPanel extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        units: []
    }    
    axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
    axios.get('/api/adminunits')
    .then(res => {
        this.setState({units: res.data})
    })
  }
  units = () => {

  }
  render() {
    return (    
        
        <BaseConsumer>
         {props => {
                    return(
        <div>  
        {this.state.moderator &&
          <div>
            <Link to="/addupdate">Add Update</Link><br />
            <Link to="/addunit">Add Unit</Link><br />
            <Link to="/addmodel">Add Model</Link><br />
            <Link to="/addweapon">Add Weapon</Link><br />
            <Link to="/addspecial">Add Special</Link><br />
            <Link to="/addfaction">Add Faction</Link><br />
            <Link to="/addsubfaction">Add Subfaction</Link><br />
            <Link to="/addunittype">Add Unit Type</Link><br />
            <Link to="/addorder">Add Order</Link><br />
            <Link to="/editunits">Edit Units</Link><br />
            <Link to="/editmodels">Edit Models</Link><br />
            <Link to="/editspecials">Edit Specials</Link><br />
            <Link to="/editorders">Edit Orders</Link><br />
            <Link to="/editupdates">Edit Updates</Link><br />
            <Link to="/editweapons">Edit Weapons</Link><br />
            <Link to="/editfactions">Edit Factions</Link><br />
            <Link to="/editsubfactions">Edit Subfactions</Link><br />
            <Link to="/updateversion">Update Game Version</Link><br />
            <Link to="/updatepoints">Update Max Points</Link><br />
            <Link to="/updateads">Update Ads</Link><br />
           </div>
        }
        {this.state.admin &&
          <div>
            <Link to="/editusers">Edit Users</Link><br />
            </div>
         }
        </div>
        
                    )}}
        </BaseConsumer>
    );
  }
}
export default AdminPanel;