import React, { Component } from 'react';
import axios from 'axios';
import BuilderContext from '../BuilderContext'
import { BuilderConsumer } from '../BuilderContext'
import CorreRepublic from '../styles/Corre Republic.png'
import UnionOfStars from '../styles/Union of Stars.png'

class SelectFaction extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        factions: []
    }
  }
  componentDidMount() {      
    axios.get('/api/factions')
    .then(result => {
        this.setState({factions: result.data})
    })
    console.log(this.props.faction)
  }
  render() {
      return(
        <BuilderConsumer>
         {props => {
                    return(
          <div>
              {this.state.factions.map(faction => {
                return(                
                  <div key={faction.id} className="option-unit-name faction" onClick={() => props.chooseFaction(faction)}><img className="faction-icon" src={faction.image} />{faction.name}<img className="faction-icon" src={faction.image} /></div>
              )})}
        </div>
                    )}}
         </BuilderConsumer>
      )
}
}
export default SelectFaction;