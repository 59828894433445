import React, { Component } from 'react';
import BuilderContext from '../BuilderContext'
import { BuilderConsumer } from '../BuilderContext'
import '../styles/card.css';

class UnitInfo extends Component {
    static contextType = BuilderContext
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {     
    
  }
  
  render() {
      return(
          <div>
            <div className="order-name">{this.props.order.name}</div>
            <div className="order-description">{this.props.order.description}</div>
        </div>
      )
}
}
export default UnitInfo;