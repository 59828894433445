import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        error: '',
        admin: false,
        moderator: false
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
  }
  
  componentDidMount() { 
    axios.get('/api/model/' + this.props.match.params.id)
    .then(result => {
        this.setState({Name: result.data.name, Description: result.data.description})
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

onSubmit = (e) => {
    e.preventDefault();       
    var model = {
        id: this.props.match.params.id,
        name: this.state.Name,
    }
    axios.post('/api/updatemodel', model)
            .then(response => {
                if(response.data.name=="error"){
                    this.setState({error: "Error. Check variable types"})
                } else{
                  window.location.reload();
                }
            })
}

  render() {
    return (    
        <div>  
            {this.state.moderator &&
        <form onSubmit={this.onSubmit}>
        <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
        <div className="error">
            {this.state.error}
        </div>
        <div className="form-group">
            <input type="submit" value="Update Model" className="btn btn-primary" />
        </div>
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateModel;