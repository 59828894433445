import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class Collection extends Component {
  constructor(props) {
    super(props);
    this.state = {
        owned: '',
        units: []
      };
  }
  
  componentDidMount() { 
    axios.get('/api/officialbaseunits/')
    .then(result => {
        this.setState({units: result.data}, () => {
          axios.get('/api/getowned')
          .then(response => {
            this.setState({owned: response.data}, () => {          
              var o = this.state.owned
              var u = this.state.units
              u.map(un => {
                un.quantity = 0
              })
              o.map(ow => {
                u.forEach((element, index) => {
                  if(element.id == ow.unit_fkey || element.unit_fkey == ow.unit_fkey){
                    element.quantity = ow.quantity
                  }
                })
              })            
            this.setState({units: u.sort((a, b) => a.name > b.name ? 1 : -1)})
            })
          })
        })
      })
    }
  handleChange = (e) => {
    var u = this.state.units;
    u.forEach((element, index) => {
      if(element.name == e.target.name){
        element.quantity = e.target.value
      }
    })
    this.setState({units: u})
  }

  owned = () => {
    return this.state.units.map((unit, index) => {
        return(
          <div className="collection">
          <label>{unit.name}</label>
          <input className="collection-input" onChange={this.handleChange}type="number" name={unit.name} value={unit.quantity} />
          </div>
        )
    })
}
onSubmit = (e) => {
    e.preventDefault();       
    axios.post('/api/updatecollection', {units: this.state.units})
            .then(response => {
                if(response.data.name=="error"){
                    this.setState({error: "Error. Check variable types"})
                } else{
                  window.location.reload();
                }
            })
}

  render() {
    return (    
        <div>  
        <div className="header">My Collection</div>
        <br />
          <form onSubmit={this.onSubmit}>
          {this.owned()}
          <div className="button-wrapper">
          <button className="submit" type="submit">Submit</button>
          </div>
          </form>
    
    </div>
    );
  }
}
export default Collection;