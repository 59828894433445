import React, { Component } from 'react';
import axios from 'axios';
import BuilderContext from '../BuilderContext'
import { BuilderConsumer } from '../BuilderContext'

class SelectSubfaction extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        subfactions: this.props.subfactions
    }
  }
  render() {
      return(
        <BuilderConsumer>
         {props => {
                    return(
          <div className="options-wrapper">
            <div className="header">Choose Subfaction</div>
              {this.state.subfactions.filter(s => s.faction_fkey == props.faction.id).map(subfaction => {
                  return(
                  <div key={subfaction.id} className="option-unit-name" onClick={() => props.chooseSubfaction(subfaction)}>{subfaction.name}</div>
                  )
              })}
              <br /><br />
              <div className="back-wrapper"><span className="back" onClick={props.resetFaction}>RESTART</span></div>
        </div>
                    )}}
         </BuilderConsumer>
      )
}
}
export default SelectSubfaction;