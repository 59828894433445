import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateFaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        Name: '',
        image: '',
        error: ''
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
  }
  
  componentDidMount() { 
    axios.get('/api/faction/' + this.props.match.params.id)
    .then(result => {
        this.setState({Name: result.data.name, image: result.data.image})
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

onSubmit = (e) => {
    e.preventDefault();       
    var faction = {
        id: this.props.match.params.id,
        name: this.state.Name,
        image: this.state.image
    }
    axios.post('/api/updatefaction', faction)
            .then(response => {
                if(response.data.name=="error"){
                    this.setState({error: "Error. Check variable types"})
                } else{
                  window.location.reload();
                }
            })
}
uploadImage = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append('file', files[0])
    data.append('upload_preset', 'card_art')
    const res = await fetch(
        'https://api.cloudinary.com/v1_1/dd1mrevav/image/upload',
        {
            method: 'POST',
            body: data
        }
    )
    const file = await res.json()    
    this.setState({image: file.secure_url});
    
  }

  render() {
    return (    
        <div>  
            {this.state.moderator &&
        <form onSubmit={this.onSubmit}>
        <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
        <div style={{textAlign:'center'}}>
            <label>Faction Image </label> 
        <input type="file"
                    name="file"
                    placeholder="Upload an image"
                    onChange={this.uploadImage}
                /><br />
            <img src={this.state.image} /><br /><br />
            </div>
        <div className="error">
            {this.state.error}
        </div>
        <div className="form-group">
            <input type="submit" value="Update Faction" className="btn btn-primary" />
        </div>
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateFaction;