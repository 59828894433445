
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseContext from '../BaseContext'
import { BaseConsumer } from '../BaseContext'
import axios from 'axios';
import '../styles/navbar.css';

class EditFactionsList extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        factions: [],
        error: ''
    }    
    axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
    axios.get('/api/factions')
    .then(res => {
        this.setState({factions: res.data})
    })
  }
  delete = (id) => {
      var faction = {id: id}
      axios.post('/api/factiondelete', faction)
      .then(result => {
            if(!result.data.passed){
                var units = ''
                result.data.units.map(u => units+=u.name + ", ")
                this.setState({error: "Must remove this faction from " + units})
            } else{
                window.location = "/editfactions"
            }
      })
  }
  factions = () => {
      return this.state.factions.map((faction) => {
          return(
          <div><Link to={`/updatefaction/${faction.id}`}>{faction.name}</Link> {this.state.admin &&<span onClick={() => this.delete(faction.id)}>X</span>}</div>
          )
      })
  }
  render() {
    return (    
        
        <BaseConsumer>
         {props => {
                    return(
        <div>  
        {this.state.moderator &&
        <div>
            {this.state.error}
            {this.factions()}
           </div>
        }
        </div>
                    )}}
        </BaseConsumer>
    );
  }
}
export default EditFactionsList;

