import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class CreateSubfaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        Faction: 1,
        admin: false,
        factions: []
      };
      axios.get('/auth/checkadmin')
      .then(res => {
          if(!res.data.admin){
            window.location = "/"
          } else{
            this.setState({admin: true})
          }
      })
  }
  componentDidMount(){      
    axios.get('/api/factions')
    .then(result => {
        this.setState({factions: result.data})
    })
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
onSubmit = (e) => {
    e.preventDefault();       
    var subfaction = {
        name: this.state.Name,
        faction: this.state.Faction
    }
    axios.post('/api/subfaction', subfaction)
            .then(response => {
                this.setState({Name: '', Faction: 1})
            })
}

  render() {
    return (    
        <div>  
          {this.state.admin &&
            <form onSubmit={this.onSubmit}>
            <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />         
            <div style={{textAlign:'center'}}>
            
            <div className="form-group">
            <label>Faction</label>
            <select value={this.state.Faction} name="Faction" onChange={this.handleChange}>
                {this.state.factions.map(faction => {
                    return(
                        <option value={faction.id}>{faction.name}</option>
                    )
                })}
            </select>
            </div><br /><br />
            </div>    
            <div className="form-group">
                <input type="submit" value="Create Faction" className="btn btn-primary" />
            </div> 
            </form>    
          }
        </div>
    );
  }
}
export default CreateSubfaction;