import React, { Component } from 'react';
import BuilderContext from '../BuilderContext'

class UnitList extends Component {
    static contextType = BuilderContext
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {      
  }
  units(){
    return this.props.units.filter(unit => {
      return (unit.unit_fkey == this.context.baseUnit || unit.id == this.context.baseUnit) && unit.faction_fkey === parseInt(this.context.faction.id) && (unit.subfaction_fkey == this.context.subfaction.id || !this.context.subfaction.name) && unit.unit_type_fkey == this.context.unitType.id
    })
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(unit => {
      var styleClass = "option-wrapper"
      if(unit.remaining < 1 && !unit.user_fkey) { styleClass += " option-warning"}
      if(!unit.user_fkey || this.context.showCustom){
          return (
              <div key={unit.id} className={styleClass}>
              <span className="option-unit-name" onClick={() => this.context.showUnit(unit)} key={unit.id}>
                {unit.name} [{unit.cost}]
              {unit.user_fkey ? "[CUSTOM]" : ""}
              </span>
              <span className="add-button" onClick={() => this.context.addUnit(unit)}>+</span>
              </div>
              )
          }
          }
    )
  }
  resetBaseUnit = () => {
    this.context.resetBaseUnit()
  }

  render() {
      return(
          <div>
          <div className="header">Choose Unit</div>
          <div className="back-wrapper"><span className="back" onClick={this.resetBaseUnit}> 🡨 Base Units</span></div>
            {this.units()}
        </div>
      )
}
}
export default UnitList;