import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Prompt } from "react-router-dom";
import { BuilderProvider } from '../BuilderContext'
import UnitList from './unit-list.component'
import Army from './army.component'
import ChosenOrders from './chosen-orders.component'
import UnitInfo from './unit-info.component'
import OrderInfo from './order-info.component'
import SelectFaction from './select-faction.component'
import Print from './print.component'
import SelectUnitType from './select-unit-type.component'
import SelectSubfaction from './select-subfaction.component'
import BaseUnitList from './base-unit-list.component'
import OrderList from './order-list.component'
import BaseContext from '../BaseContext'
import { Link } from 'react-router-dom';
import axios from 'axios';

class ListBuilder extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
        units: [],
        orders: [],
        faction: {},
        subfaction: {},
        factions: [],
        subfactions: [],
        savedFaction: 0,
        showUnit: this.showUnit,
        showOrder: this.showOrder,
        addUnit: this.addUnit,
        viewing: {},
        chooseFaction: this.chooseFaction,
        chooseUnitType: this.chooseUnitType,
        resetUnitType: this.resetUnitType,
        resetBaseUnit: this.resetBaseUnit,
        resetSubfaction: this.resetSubfaction,
        resetFaction: this.resetFaction,
        chooseBaseUnit: this.chooseBaseUnit,
        chooseSubfaction: this.chooseSubfaction,
        army: [],
        chosenOrders: [],
        weapons: [],
        specials: [],
        unitTypes: [],
        name: '',
        cost: 0,
        maximum: 300,
        unitType: 0,
        baseUnit: 0,
        removeUnit: this.removeUnit,
        removeOrder: this.removeOrder,
        addOrder: this.addOrder,
        id: this.props.match.params.id,
        printing: false,
        togglePrint: this.togglePrint,
        editing: false,
        loaded: false,
        version: '',
        ad: '',
        ad_url: '',
        showCustom: true
      };
  }

  componentDidMount(){  

    axios.get('/api/checkversion')
    .then(result => {
      this.setState({version: result.data})
    })
    axios.get('/api/checkpoints')
    .then(result => {
        this.setState({maximum: result.data})
    })
    axios.get('/api/builderad')
    .then(result => {
      this.setState({ad: result.data})
    })
    axios.get('/api/builderadurl')
    .then(result => {
      this.setState({ad_url: result.data})
    })
    axios.get('/api/subfactions')
    .then(result => {
      this.setState({subfactions: result.data})
    })

     axios.get('/api/factions')
    .then(result => {
      console.log(result.data)
        this.setState({factions: result.data})
    

    //THIS IS IF THEY'RE LOADING A LIST
    if(this.state.id){
      axios.get('/api/list/'+ this.state.id)
      .then(result => {
        if(result.data.user == result.data.list.username){
          this.setState({editing: true})
        }
        this.setState({army: result.data.list.units, chosenOrders: result.data.list.orders, savedFaction: result.data.list.faction_fkey, name: result.data.list.name}, () => {
          if(!this.state.chosenOrders){
            this.setState({chosenOrders: []})
          }
        })
        var savedfac = this.state.factions.filter((fac) => {
          return fac.id == result.data.list.faction_fkey
        })
        this.setState({faction: savedfac[0]}, () => {

        //GETS UNITS TO CHOOSE FROM FOR LOADED LISTS. BUT IT DOES IT AGAIN LATER SO I SHOULD FIX THAT
        axios.get('/api/units')
        .then(result => {
            this.setState({units: result.data}, () => {
              var refreshedArmy = [];
              this.state.army.map(u => {
                if(this.state.units.filter(un => un.id == u.id).length > 0){
                  refreshedArmy.push(this.state.units.filter(un => un.id == u.id)[0])
                }
              })
              this.setState({army: refreshedArmy, loaded: true}, () => {
                var cost = 0
                this.state.army.map(u => {
                  cost += u.cost
                })
                this.setState({cost: cost})
              })
              })

            })
        })    
         
      })
    } else{
      this.setState({loaded: true})
    }
    })
    //END OF LIST LOADING HANDLING


    axios.get('/api/unittypes')
    .then(result => {
        this.setState({unitTypes: result.data})
    })
    this.setState({units: [], faction: {}, viewing: {}, viewingOrder: {}, army: [], chosenOrders: [], cost: 0, unitType: 0})
    axios.get('/api/units')
    .then(result => {
        this.setState({units: result.data.filter(d => !d.disabled)}, () => {
          var u = this.state.units
          u.map(un => un.remaining = 0)
          axios.get('/api/getowned')
          .then(response => {
            this.setState({owned: response.data}, () => {
          //SET REMAINING TO QUANTITY OWNED
          var o = this.state.owned
          if(o.length > 0){
            o.map(ow => {
              u.forEach((element, index) => {
                if(element.id == ow.unit_fkey || element.unit_fkey == ow.unit_fkey){
                  element.remaining = ow.quantity
                }
              })
              //u[u.findIndex(el => el.id == ow.unit_fkey)].remaining = ow.quantity
            })
          }
        })
    })
          
        })
    })    
    axios.get('/api/orders')
    .then(result => {
        this.setState({orders: result.data})
    })  
    axios.get('/api/unitweapons')
    .then(result => {
        this.setState({weapons: result.data})
    })   
    
    axios.get('/api/unitspecials')
    .then(result => {
        this.setState({specials: result.data})
    })   
    
  }
  
  downloadTextFile = () => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL( new Blob([JSON.stringify({ units: this.state.army.map(u => u.id)})], { type:`text/json` }) );
    a.download = this.state.name ? `${this.state.name}.json` : 'list.json';
    a.click();
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  showUnit = (unit) => {
    this.setState({viewing: unit})
  }
  showOrder = (unit) => {
    this.setState({viewingOrder: unit})
  }
  addUnit = (unit) => {
    this.setState({ army: [...this.state.army, unit], cost: this.state.cost + unit.cost })
    var u = this.state.units;
    if(!unit.unit_fkey){
      u.forEach((element, index) => {
        if(element.id == unit.id || element.unit_fkey == unit.id){
          element.remaining -= 1
        }
      })
    } else{
      u.forEach((element, index) => {
        if(element.id == unit.unit_fkey || element.unit_fkey == unit.unit_fkey){
          element.remaining -= 1
        }
      })
    }
    this.setState({units: u})
  };

  addOrder = (order) => {
    this.setState({ chosenOrders: [...this.state.chosenOrders, order]})
  };

  chooseFaction = (faction) => {
    this.setState({faction: faction})
  }

  chooseSubfaction = (subfaction) => {
    this.setState({subfaction: subfaction})
  }
  
  chooseBaseUnit = (unit) => {
    this.setState({baseUnit: unit.id}, () => console.log(this.state.baseUnit))
  }
  chooseUnitType = (unitType) => {
    this.setState({unitType: unitType})
  }
  resetSubfaction = () => {
    this.setState({subfaction: {}})
  }

  resetUnitType = () => {
    this.setState({unitType: 0, baseUnit: 0})
  }
  resetBaseUnit = () => {
    this.setState({baseUnit: 0})
  }
  resetFaction = () => {
    this.setState({faction: {}, army: [], cost: 0, viewing: {}, viewingOrder: {}, unitType: 0, subfaction: {}, baseUnit: 0})
  }
  removeUnit = (unit) => { 
    var newArray = [...this.state.army]
    newArray.splice(unit, 1)
    this.setState({army: newArray}, () => {
      var cost = 0
      this.state.army.map(u => {
        cost += u.cost
      })
      this.setState({cost: cost})
    })
    var u = this.state.units;
    if(!unit.unit_fkey){
      u.forEach((element, index) => {
        if(element.id == unit.id || element.unit_fkey == unit.id){
          element.remaining += 1
        }
      })
    } else{
      u.forEach((element, index) => {
        if(element.id == unit.unit_fkey || element.unit_fkey == unit.unit_fkey){
          element.remaining += 1
        }
      })
    }
    this.setState({units: u})
  }
  removeOrder = (order) => { 
    var newArray = [...this.state.chosenOrders]
    newArray.splice(order, 1)
    this.setState({chosenOrders: newArray})
  }

  togglePrint = () => {
    this.setState({printing: !this.state.printing}, () => {
      if(this.state.printing){
        window.scrollTo(0,0)
      }
    })
  }
  toggleCustomUnits = () => {
    this.setState({showCustom: !this.state.showCustom}, () => {
    })
  }
  submitList = (e) => {
    e.preventDefault();
    var list = {
      faction: this.state.faction.id,
      units: this.state.army,
      name: this.state.name,
      orders: this.state.chosenOrders
    }
    axios.post('/api/list', list)
    .then(response => {
      window.location="/builder/"+response.data[0].id
    })
  }
  
  updateList = (e) => {
    e.preventDefault();
    var list = {
      faction: this.state.faction.id,
      units: this.state.army,
      orders: this.state.chosenOrders,
      name: this.state.name
    }
    axios.post('/api/list/' + this.state.id, list)
    .then(response => window.location="/builder/" + this.state.id)
  }
  render() {
    return (
      
    <BuilderProvider value={this.state}>
      {!this.state.printing &&
      <div className="main">
        <div style={{textAlign:'center'}}><a target="_blank" rel="noopener noreferrer" href={this.state.ad_url}><img style={{maxWidth: '100%'}} src={this.state.ad} /></a></div>
      <div>{this.state.version}</div>
        {this.state.faction.name &&
        <div className="builder-top">    
        <div className="save-button-wrapper top-desktop" style={{textAlign: 'center'}}>
          {this.state.faction.name && this.state.cost > 0 &&<button onClick={this.submitList} className="save-button save-button-top" onClick={this.togglePrint}>PRINT</button>}
          {this.state.faction.name && this.state.cost > 0 && false && <button onClick={this.downloadTextFile} className="save-button save-button-top" style={{width: 'inherit', marginLeft: '12px'}}>Download JSON</button>}
        </div>
        <div className="name-change"><input name="name" onChange={this.handleChange} placeholder="Name Your List" value={this.state.name} /></div>
        
        <div className="save-button-wrapper top-desktop">
            {this.state.editing && this.state.cost > 0 && localStorage.user_id &&
              <button className="save-button save-button-top" onClick={this.updateList}>Update</button>
            }  
            {this.state.editing && this.state.cost > 0 && localStorage.user_id &&
                <button className="save-button save-button-top save-copy-button" onClick={this.submitList} type="submit">SAVE COPY</button>
            }  
            {!this.state.editing && this.state.cost > 0 && localStorage.user_id &&
                <button className="save-button save-button-top" onClick={this.submitList} type="submit">SAVE</button>
            }
          </div>
       
        </div>
        }
        <div className="list-builder-page">
          {this.state.faction.name && 
            <div style={{textAlign: 'center'}}><input type="checkbox" checked={this.state.showCustom} onChange={this.toggleCustomUnits}/><span> Show Custom Units</span></div>
          }
            {this.state.faction.name && this.state.cost > 0 &&
            <div className="top-mobile-buttons">
            <div className="save-button-wrapper top-mobile" style={{textAlign: 'center'}}>
              {this.state.faction.name && this.state.cost > 0 &&<button className="save-button save-button-top" onClick={this.togglePrint}>PRINT</button>}
              {this.state.faction.name && this.state.cost > 0 && false && <button className="save-button save-button-top" onClick={this.downloadTextFile} style={{width: 'inherit', marginLeft: '12px'}}>Download JSON</button>}
            </div>
            <div className="save-button-wrapper top-mobile">
            {this.state.editing && this.state.cost > 0 && localStorage.user_id &&
              <button className="save-button save-button-top" onClick={this.updateList}>Update</button>
            }  
            {this.state.editing && this.state.cost > 0 && localStorage.user_id &&
                <button className="save-button save-button-top save-copy-button" onClick={this.submitList} type="submit">SAVE COPY</button>
            }  
            {!this.state.editing && this.state.cost > 0 && localStorage.user_id &&
                <button className="save-button save-button-top" onClick={this.submitList} type="submit">SAVE</button>
            }
          </div>         
            </div>
          }
        {!this.state.faction.name &&
          <SelectFaction faction={this.state.savedFaction}/>
        }
        <div className="list-builder">
          {this.state.faction.name && this.state.unitType === 0 && (this.state.subfactions.filter(s => s.faction_fkey == this.state.faction.id).length == 0 || this.state.subfaction.name) &&
       <div className="unit-options">
            <SelectUnitType types = {this.state.unitTypes} />
            </div>
          }          
          {this.state.faction.name && this.state.unitType === 0 && this.state.subfactions.filter(s => s.faction_fkey == this.state.faction.id).length > 0 && !this.state.subfaction.name &&
       <div className="unit-options">
            <SelectSubfaction subfactions={this.state.subfactions} chooseSubfaction={this.state.chooseSubfaction} />
            </div>
          }
       {this.state.faction.name && this.state.unitType !== 0 && this.state.baseUnit === 0 && (this.state.subfactions.filter(s => s.faction_fkey == this.state.faction.id).length == 0 || this.state.subfaction.name) &&
       <div className="unit-options">
          <BaseUnitList units={this.state.units} />
              <div className="back-wrapper"><span className="back" onClick={this.resetFaction}>RESTART</span></div>
          </div>
      }
    {this.state.faction.name && this.state.baseUnit !== 0 && this.state.unitType !== 0 &&
          <div className="unit-options">
            <UnitList units={this.state.units} />
              <div className="back-wrapper"><span className="back" onClick={this.resetFaction}>RESTART</span></div>
            </div>
            
  }
 
{this.state.faction.name &&
        <div className="unit-info">
            <UnitInfo unit={this.state.viewing}/>
            {this.state.viewing.stl_url &&
              <div className="buy-link"><br /><a target="_blank" rel="noopener noreferrer" href={this.state.viewing.stl_url}>Purchase the pre-supported .stls here</a></div>
            }
            {this.state.viewing.buy_url &&
              <div className="buy-link"><br /><a target="_blank" rel="noopener noreferrer" href={this.state.viewing.buy_url}>Purchase the physical model here</a></div>
            }
        </div>
  }
        {this.state.faction.name && this.state.loaded &&
        <div className="list">
            <div className="right-pane">
          <div className="header">Current List</div>
            <div className="total-cost">{this.state.army.length} Units</div>
            <div className="total-cost">{this.state.cost}/<input className="maximum" name="maximum" value={this.state.maximum} onChange={this.handleChange} /></div>
            </div>
            <Army />
        </div>
      }
        </div>
      
      </div>
      <div className="list-builder list-builder-bottom">
      {this.state.faction.name &&
          <div className="unit-options">
            <OrderList orders={this.state.orders.filter(o => !o.faction_fkey || o.faction_fkey == this.state.faction.id)} />
            </div>
            
      }
      
    {this.state.faction.name &&
          <div className="unit-info">
              <OrderInfo order={this.state.viewingOrder}/>
          </div>
    }
    {this.state.faction.name &&
        <div className="list">
            <div className="right-pane">
          <div className="header">Chosen Orders</div>
            <div className="total-cost">{this.state.chosenOrders.length}/9</div>
            </div>
            <ChosenOrders />
        </div>
      }
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', marginBottom: '10px'}}>
      {this.state.faction.name && this.state.cost > 0 &&
          <div style={{textAlign: 'center'}}><button className="save-button" onClick={this.togglePrint}>PRINT</button></div>
      }
      {localStorage.user_id && this.state.faction.name && this.state.cost > 0 &&
      <form className="save-form save-button-wrapper" onSubmit={this.submitList}>
      {this.state.editing &&
        <button className="save-button" onClick={this.updateList}>Update</button>
      }  
      {this.state.editing &&
          <button className="save-button save-copy-button" type="submit">SAVE COPY</button>
      }  
      {!this.state.editing &&
          <button className="save-button" type="submit">SAVE</button>
      }
        </form>
      } 
      </div>
  </div>
  }
  {this.state.printing &&
    <Print />
  }
      </BuilderProvider>
    );
  }
}
export default ListBuilder;