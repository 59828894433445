import React, { Component } from 'react';
import axios from 'axios';
import BuilderContext from '../BuilderContext'
import UnitInfo from './unit-info.component'
import { BuilderConsumer } from '../BuilderContext'

class Print extends Component {
  static contextType = BuilderContext    
  constructor(props) {
    super(props);
  }
  componentDidMount() {      
  }
  units = () =>{
    return this.context.army.map((unit, index) => {
            return (
              <div style={{pageBreakInside: 'avoid'}}>
                    <UnitInfo unit={unit} print={true} />
                    </div>
                )
    })
    }
    
  summary = (units) =>{
    console.log(units)
    return units.map((unit, index) => {
            return (
              <div className="summary-unit" style={{pageBreakInside: 'avoid'}}>
                    {unit.name} [{unit.cost}]
                    </div>
                )
    })
    }
  
  orderSummary = () => {
    return this.context.chosenOrders.map((order, index) => {
      return (
        <div className="summary-unit" style={{pageBreakInside: 'avoid'}}>
          {order.name}
        </div>
      )
    })
  }


  render() {
      return(
        <BuilderConsumer>
         {props => {
                    return(
          <div className="print">
          <div className="print-print" onClick={window.print}>PRINT</div>
          <div className="print-back" onClick={props.togglePrint}>BACK</div>
          <div className="print-top">
            <div className="print-top-left">
            <div className="print-title">{props.name}</div>
            <div className="print-faction">{props.faction.name}</div>
            <div className="print-points">{props.army.length} Units | {props.cost} Points</div>
            </div>
            <div className="print-logo"><img style={{width: '175px'}} src={props.faction.image} /></div>
            </div>
            <div className="summary">
            <div className="print-middle">
              <div className="print-company">
              {this.context.army.filter(a => a.unit_type_fkey != 7).length > 12 && <div className="print-company-name">Alpha Company</div>}
              {this.summary(this.context.army.filter(a => a.unit_type_fkey != 7).slice(0,12))}
              </div>
              {this.context.army.filter(a => a.unit_type_fkey != 7).length > 12 &&
                <div className="print-company">
                  <div className="print-company-name">Beta Company</div>
                  {this.summary(this.context.army.filter(a => a.unit_type_fkey != 7).slice(12,24))}
                </div>
              }
              {this.context.army.filter(a => a.unit_type_fkey != 7).length > 24 &&
                <div className="print-company">
                  <div className="print-company-name">Delta Company</div>
                  {this.summary(this.context.army.filter(a => a.unit_type_fkey != 7).slice(24,this.context.army.filter(a => a.unit_type_fkey != 7).length + 1))}
                </div>
              }

            </div>
            <div className="print-bottom">
            {this.context.army.filter(a => a.unit_type_fkey == 7).length > 0 &&
                <div className="print-company">
                  <div className="print-company-name">Drones</div>
                  {this.summary(this.context.army.filter(a => a.unit_type_fkey == 7))}
                </div>
              }
            <div>
            <div className="print-company-name">Order Cards x{this.context.chosenOrders.length}</div>
              {this.orderSummary()}
            </div>
            </div>
            </div>
            <div className="print-unit-wrapper">
              {this.units()}
            </div><br /><br />
            <div className="version">{props.version}</div>
        </div>
      )
                    }}
          </BuilderConsumer>
      )}
}
export default Print;