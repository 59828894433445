import React, { Component } from 'react';

export default class BasicField extends Component {
    render() {
        return (
            <div className="form-group">
            <label>{this.props.label} </label>
            <input 
            type="text"
            className="form-control"
            value={this.props.valueState}
            onChange={this.props.onChangeState}
            name={this.props.label}
            />
        </div>
        );
    }
}