import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseContext from '../BaseContext'
import { BaseConsumer } from '../BaseContext'
import axios from 'axios';
import '../styles/navbar.css';

class Navbar extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
        username: '',
        password: ''
    }
  }
  login = (event) => {
      event.preventDefault();
      const user = {
          username: this.state.username,
          password: this.state.password
      }
    axios.post('/auth/login', {
        withCredentials: true,
        crossDomain: true,
        data: user
    },
    user)
    .then(result => {
        console.log(result)
    })      
  }
  signup = (event) => {
      event.preventDefault();
      const user = {
          username: this.state.username,
          password: this.state.password
      }
    axios.post('/auth/signup', {
        withCredentials: true,
        crossDomain: true,
        data: user
    },
    user)
    .then(result => {
        console.log(result)
    })      
  }
handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    return (    
        
        <BaseConsumer>
         {props => {
                    return(
        <div className="navbar">  
            <div className="brand"><Link to="/">Revelations</Link></div>
            <div><Link to="/builder">List Builder</Link></div>
            <div><Link to="/latest">Latest Lists</Link></div>
            {localStorage.user_id &&            
                <div><Link to="/profile">My Profile</Link></div>
            }
            <div><a href="https://revelations-minis.myshopify.com">Store</a></div>
            {localStorage.user_id &&            
                <div><Link to="/" onClick={props.logout}>Logout</Link></div>
            }
            {!localStorage.user_id &&
                <div><Link to="/signin">Sign In/Up</Link></div>
            }
        </div>
                    )}}
        </BaseConsumer>
    );
  }
}
export default Navbar;