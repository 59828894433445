import React, { Component } from 'react';
import axios from 'axios';
import BuilderContext from '../BuilderContext'
import { BuilderConsumer } from '../BuilderContext'

class ChosenOrders extends Component {
  static contextType = BuilderContext    
  constructor(props) {
    super(props);
  }
  componentDidMount() {      
    
  }
  orders = () =>{
    return this.context.chosenOrders.map((order, index) => {
            return (
              <div key={index} className="list-unit-wrapper">
                <span className="add-button" onClick={() => this.context.removeOrder(index)}>-</span>
                <span className="option-unit-name">
                  {order.name}
                </span>
                </div>
                )
    })
    }

  render() {
      return(
        <BuilderConsumer>
         {props => {
                    return(
          <div>
            {this.orders()}
        </div>
      )
                    }}
          </BuilderConsumer>
      )}
}
export default ChosenOrders;