import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: '',
      Description: '',
      admin: false,
      Faction: null,
      Subfaction: null,
      factions: [],
      subfactions: [],
      error: '',
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
  }
  
  componentDidMount() { 
    axios.get('/api/factions')
    .then(result => {
        this.setState({factions: result.data})
    })
    axios.get('/api/subfactions')
    .then(result => {
        this.setState({subfactions: result.data})
    })
    axios.get('/api/order/' + this.props.match.params.id)
    .then(result => {
        this.setState({Name: result.data.name, Description: result.data.description, Faction: result.data.faction_fkey, Subfaction: result.data.subfaction_fkey})
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

onSubmit = (e) => {
    e.preventDefault();       
    var order = {
        id: this.props.match.params.id,
        name: this.state.Name,
        description: this.state.Description,
        faction: this.state.Faction,
        subfaction: this.state.Subfaction,
    }
    axios.post('/api/updateorder', order)
            .then(response => {
                if(response.data.name=="error"){
                    this.setState({error: "Error. Check variable types"})
                } else{
                  window.location.reload();
                }
            })
}

  render() {
    return (    
        <div>  
            {this.state.moderator &&
        <form onSubmit={this.onSubmit}>
        <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
        <div className="form-group">
            <label>Description</label>
            <textarea 
            type="text"
            className="form-control"
            value={this.state.Description}
            onChange={this.handleChange}
            name="Description"
            style={{height: 100}}
            />
        </div>
        <div className="form-group">
            <label>Faction</label>
            <select value={this.state.Faction} name="Faction" onChange={this.handleChange}>
              <option value={null}>----</option>
                {this.state.factions.map(faction => {
                    return(
                        <option value={faction.id}>{faction.name}</option>
                    )
                })}
            </select>

            
            <label>Subfaction</label>
            <select value={this.state.Subfaction} name="Subfaction" onChange={this.handleChange}>
              <option value={null}>----</option>
                {this.state.subfactions.filter(f => f.faction_fkey == this.state.Faction).map(subfaction => {
                    return(
                        <option value={subfaction.id}>{subfaction.name}</option>
                    )
                })}
            </select>
        </div>      
        <div className="error">
            {this.state.error}
        </div>
        <div className="form-group">
            <input type="submit" value="Update Order" className="btn btn-primary" />
        </div>
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateOrder;