import React, { Component } from 'react';
import axios from 'axios';
import BuilderContext from '../BuilderContext'
import * as linkify from 'linkifyjs'
import linkifyHtml from 'linkifyjs/html'
import { BuilderConsumer } from '../BuilderContext'

class News extends Component {
  static contextType = BuilderContext    
  constructor(props) {
    super(props);
    this.state = {
      updates: [],
      ad: '',
      ad_url: ''
    }
  }
  componentDidMount() {          
    axios.get('/api/updates')
    .then(result => {
        this.setState({updates: result.data})
    })

    axios.get('/api/updatesad')
    .then(result => {
      this.setState({ad: result.data})
    })
    axios.get('/api/updatesadurl')
    .then(result => {
      this.setState({ad_url: result.data})
    })
    
  }

  
  updates = () =>{
    return this.state.updates.map((update) => {
      var d = new Date(update.timestamp.replace(' ', 'T'));
            return (
              <div key={update.id} className="update-wrapper">
                <div className="update-title">{update.title} <br/><span className="update-date">{d.toLocaleString('default', { month: 'long' })} {d.getDate()}, {d.getFullYear()}</span></div>
                <div className="update-body" dangerouslySetInnerHTML={{ __html: linkifyHtml(update.body, {target: "__blank"})}}></div>
                </div>
                )
    })
    }


  render() {
      return(
        <BuilderConsumer>
         {props => {
                    return(
          <div className="" style={{whiteSpace: "pre-wrap"}}>
            
        <a target="_blank" rel="noopener noreferrer" href={this.state.ad_url}><div style={{textAlign:'center'}}><img style={{maxWidth: '100%'}} src={this.state.ad} /></div></a>
          <div className="header news-header">UPDATES</div>
              {this.updates()}
        </div>
      )
                    }}
          </BuilderConsumer>
      )}
}
export default News;