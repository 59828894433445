import React, { Component } from 'react';
import BuilderContext from '../BuilderContext'
import { BuilderConsumer } from '../BuilderContext'
import '../styles/card.css';

class UnitInfo extends Component {
    static contextType = BuilderContext
  constructor(props) {
    super(props);
    this.state = {
      LA: '',
      RA: '',
      T: '',
      PilotKeywords: []
    }
  }
  componentDidMount() {     
  }
  weapons(){
      return this.context.weapons.sort((a, b) => a.name > b.name ? 1 : -1).filter(weapon => {
        return weapon.unit_fkey === parseInt(this.props.unit.id)
      })
      .map(weapon => {
        if(this.props.print){
          return (      
            <div className="weapon-print">      
            <div className="weapon row-1">
            <div>{weapon.name}</div>
            <div>{weapon.range != 0 && weapon.range}{weapon.range == 0 && "-"}{!weapon.range && "-"}</div>
            <div>{weapon.rate_of_fire != 0 && weapon.rate_of_fire}{weapon.rate_of_fire == 0 && "-"}</div>
            <div>{weapon.damage}</div>
            <div style={{textAlign: 'center'}}>{weapon.location}</div>
            {weapon.ammo > 0 &&
            <div>{"▢".repeat(weapon.ammo)}</div>
            }
            {!weapon.ammo &&
            <div>-</div>
            }
            </div>
            
            <div className="weapon row-2">
            <div>{weapon.special_text}</div>
            </div>
            </div>
          )
        }
        return (
          
          <div className="weapon">
            <div>{weapon.name}</div>
            <div>{weapon.range != 0 && weapon.range}{weapon.range == 0 && "-"}{!weapon.range && "-"}</div>
            <div>{weapon.rate_of_fire != 0 && weapon.rate_of_fire}{weapon.rate_of_fire == 0 && "-"}</div>
            <div>{weapon.damage}</div>
            <div>{weapon.special_text}</div>
            <div style={{textAlign: 'center'}}>{weapon.location}</div>
            {weapon.ammo > 0 &&
            <div>{"▢".repeat(weapon.ammo)}</div>
            }
            {!weapon.ammo &&
            <div>-</div>
            }
          </div>
      )})
  }
  specials(){
    return this.context.specials.filter(special => {
      return special.unit_fkey === parseInt(this.props.unit.id) && !special.pilot
    })
    .sort((a, b) => a.name > b.name ? 1 : -1).map(special => {
      return (
        <div className="special">
          <div>{special.name}</div>
          <div>{special.description}</div>
        </div>
    )})
  }
  pilotSpecials(){
    return this.context.specials.filter(special => {
      return special.unit_fkey === parseInt(this.props.unit.id) && special.pilot
    })
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(special => {
      return (
        <div className="special">
          <div>{special.name}</div>
          <div>{special.description}</div>
        </div>
    )})
  }
  health(){
    var shields = this.context.weapons.filter(weapon => weapon.special_text.includes("Shield") && weapon.unit_fkey === parseInt(this.props.unit.id))    
    var LA = ''
    var totalLA = this.props.unit.left_arm_hp
    var LAshield = 0
    var RA = ''
    var totalRA = this.props.unit.right_arm_hp
    var RAshield = 0
    shields.map(shield => {
      var amount = parseInt(shield.special_text[7])
      if(shield.location == "LA"){
        LA += "S-" + "▢".repeat(amount)
        LAshield += amount
        totalLA += amount
      } else if(shield.location == "RA"){
        RA += "S-" + "▢".repeat(amount)
        RAshield += amount
        totalRA += amount
      }
    })
    if(this.props.unit.torso_hp > 0){
      return(
        <div className="health-wrapper">
          <div>Torso: {this.props.unit.torso_hp}</div>
          {this.props.unit.torso_hp<=15 && 
              <div>{"O".repeat(this.props.unit.torso_hp)}</div>
            }
            {this.props.unit.torso_hp > 15 &&
            
              <div>{"O".repeat(15)}<br />{"O".repeat(this.props.unit.torso_hp-15)} </div>
            }          
          <div>1-2</div>
          <div>LA: {this.props.unit.left_arm_hp}</div>
            {LAshield <= 0 &&
            <div>{"O".repeat(this.props.unit.left_arm_hp)}</div>
            }
            {LAshield > 0 &&
            <div>{"O".repeat(this.props.unit.left_arm_hp)}<br />{LA}</div>
            }
          <div>3</div>
          <div>RA: {this.props.unit.right_arm_hp}</div>
          {RAshield <= 0 &&
            <div>{"O".repeat(this.props.unit.right_arm_hp)}</div>
            }
            {RAshield > 0 &&
            <div>{"O".repeat(this.props.unit.right_arm_hp)}<br />{LA}</div>
            }
          <div>4</div>
          <div>Legs: {this.props.unit.legs_hp}</div>
            {this.props.unit.legs_hp<=15 && 
              <div>{"O".repeat(this.props.unit.legs_hp)}</div>
            }
            {this.props.unit.legs_hp > 15 &&
            
              <div>{"O".repeat(15)}<br />{"O".repeat(this.props.unit.legs_hp-15)}</div>
              
              }
            
          <div>5-6</div>
        </div>
      )
    }
    else{
      return(
        <div className="infantry-health-wrapper">
          <div className="health">HP: {this.props.unit.hp}</div>
          <div>{"O".repeat(this.props.unit.hp)}</div>
          {this.props.unit.figures > 1 &&          
          <div className="health">HP: {this.props.unit.hp}</div>
          }
          {this.props.unit.figures > 1 &&          
          <div>{"O".repeat(this.props.unit.hp)}</div>
          }
          {this.props.unit.figures > 2 &&          
            <div className="health">HP: {this.props.unit.hp}</div>
          }
          {this.props.unit.figures > 2 &&          
            <div>{"O".repeat(this.props.unit.hp)}</div>
          }
          {this.props.unit.figures > 3 &&          
            <div className="health">HP: {this.props.unit.hp}</div>
          }
          {this.props.unit.figures > 3 &&          
            <div>{"O".repeat(this.props.unit.hp)}</div>
          }
        </div>
      )
    }
  }
  unitInfo(){
    
        return (
            <div>
              {this.props.unit.name &&
              <div className="unit-card">
              <div className="unit-card-top">
                <div className="unit-top-left">
                  <div className="unit-faction">{this.context.faction.name}</div>
                  {this.props.unit.named_pilot &&  this.props.unit.unit_fkey &&
                  <div className="unit-name">{this.context.units.filter(u => u.id == this.props.unit.unit_fkey)[0].name}</div>
                  }                  
                  {!this.props.unit.named_pilot && this.props.unit.figures > 1 &&
                  <div className="unit-name">{this.props.unit.name} ({this.props.unit.figures})</div>
                  }
                  {!this.props.unit.named_pilot && this.props.unit.figures == 1 &&
                  <div className="unit-name">{this.props.unit.name}</div>
                  }
                  <div className="unit-type">Type: {this.props.unit.unit_type_name}</div>
                  <div className="unit-cost">Cost: {this.props.unit.cost}</div>

              <div className="unit-move">Move: {this.props.unit.walk_speed} | {this.props.unit.run_speed} {this.props.unit.jump > 0 && <span>| ({this.props.unit.jump})</span>}</div>
                  <div className="unit-melee">Melee: {this.props.unit.melee}</div>
                  <div className="unit-focus">Focus: {this.props.unit.focus}</div>
                  {this.props.unit.torso_hp > 0 &&
                  <div className="unit-pilot">Pilot: {this.props.unit.pilot}+</div>
                  }
                  </div>
                <div>           
                  {this.props.unit.named_pilot &&
                    <div className="pilot-name" style={{textAlign: 'center'}}>{this.props.unit.name}</div>
                  }     
                  {!this.props.unit.named_pilot &&
                    <br />
                  }
                  <div className="save-wrapper">
                    <div>
                    <div className="defense">Defense: {this.props.unit.defense}+</div>
                    <div className="save">Save: {this.props.unit.save}+</div>
                    </div>
                    {this.props.unit.torso_hp > 0 &&
                    <div>Loc Chart</div>
                    }
                    </div>
                  <div className="health">{this.health()}</div>
                </div>
                <div className="unit-image-wrapper">
                  <img className="unit-image" src={this.props.unit.image} />
                  </div>
              </div>
              <div className="weapon-wrapper">
              {!this.props.print &&
                <div className="weapon-headers"><div>Weapon</div><div>RNG</div><div>RoF</div><div>DMG</div><div>Specials</div><div>Loc</div><div>Ammo</div></div>
              }
              {this.props.print &&
                <div className="weapon-headers print"><div>Weapon</div><div>RNG</div><div>RoF</div><div>DMG</div><div>Loc</div><div>Ammo</div></div>
              }
                <div className="unit-weapons">{this.weapons()}</div>
              </div>
              <div className="specials-title">Keywords</div>
              <div className="unit-specials">{this.specials()}</div>
              {this.context.specials.filter(spec => { return spec.pilot && spec.unit_fkey === parseInt(this.props.unit.id) }).length > 0 &&
              <div className="specials-title">Crew Abilities</div>
              }
              {this.context.specials.filter(spec => { return spec.pilot &&  spec.unit_fkey === parseInt(this.props.unit.id)}).length > 0 &&
              <div className="unit-specials">{this.pilotSpecials()}</div>
              }
                </div>
              }
              </div>
            )
    }

  render() {
      return(
          <div style={{backgroundImage: this.props.unit.image}}>
            {this.unitInfo()}
        </div>
      )
}
}
export default UnitInfo;