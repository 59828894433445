
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseContext from '../BaseContext'
import { BaseConsumer } from '../BaseContext'
import axios from 'axios';
import '../styles/navbar.css';

class EditSpecialsList extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        specials: [],
        error: ''
    }    
    axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
    axios.get('/api/adminspecials')
    .then(res => {
        this.setState({specials: res.data})
    })
  }
  delete = (id) => {
      var special = {id: id}
      axios.post('/api/specialdelete', special)
      .then(result => {
            if(!result.data.passed){
                var units = ''
                result.data.units.map(u => units+=u.name + ", ")
                this.setState({error: "Must remove this special from " + units})
            } else{
                window.location = "/editspecials"
            }
      })
  }
  specials = () => {
      return this.state.specials.map((special) => {
          return(
              <div><Link to={`/updatespecial/${special.id}`}>{special.name}</Link> <span onClick={() => this.delete(special.id)}>X</span></div>
          )
      })
  }
  render() {
    return (    
        
        <BaseConsumer>
         {props => {
                    return(
        <div>  
        {this.state.moderator &&
        <div>
            {this.state.error}
            {this.specials()}
           </div>
        }
        </div>
                    )}}
        </BaseConsumer>
    );
  }
}
export default EditSpecialsList;

