import React, { Component } from 'react';
import axios from 'axios';
import BuilderContext from '../BuilderContext'
import { Link } from 'react-router-dom';
import { BuilderConsumer } from '../BuilderContext'

class MyLists extends Component {
  static contextType = BuilderContext    
  constructor(props) {
    super(props);
    this.state = {
        lists: [],
        units: [],
        deleting: -1
    }
  }
  componentDidMount() {      
    axios.get('/api/mylists')
    .then(response => {
        this.setState({lists: response.data})
    })
    
  axios.get('/api/myunits')
  .then(result => {
    this.setState({units: result.data}, () => console.log(this.state.units))
  })
  }
  deleteList = (id) => {
    axios.post('/api/deletelist/' + id)    
    window.location.reload();
  }

  lists = () => {
    return this.state.lists.map(list => {
        return(
            <div key={list.id} className="list-list-item"><Link to={`/builder/${list.id}`}>{list.name}{!list.name && "Unnamed List" } ({list.faction_name})</Link> | <span className="delete" onClick={() => this.deleteList(list.id)}>DELETE</span></div>
        )
    })
}
units = () => {
  return this.state.units.map((unit) => {
      return(
      <div className="list-list-item"><Link to={`/updatecustomunit/${unit.id}`}>{unit.name}</Link> | <span className="delete" onClick={() => this.confirmDelete(unit.id)}> DELETE</span></div>
      )
  })
}

confirmDelete = (unit) => {
  this.setState({deleting: unit})
}
delete = () => {
  axios.post('/api/customunitdelete', {id: this.state.deleting})
    .then(result => {
          window.location = "/profile"
    })
}
cancelDelete = () => {
  this.setState({deleting: -1})
}

  render() {
      return(
        <BuilderConsumer>
         {props => {
                    return(
                      <div className="container" style={{textAlign: 'center'}}>
                        <div>
            
      </div>
                      <div className="header">My Lists</div>
             <Link to="/builder">Create List</Link><br />
             {this.lists()}
             <br />
             <br />
             <div className="header">My Custom Units</div>
             <Link to="/addcustomunit">Create Unit</Link><br />
             {this.state.deleting != -1 &&
              <div className="confirm-delete">ARE YOU SURE?
              <div className="cancel" onClick={this.cancelDelete}>cancel</div>
              <div className="confirm" onClick={this.delete}>CONFIRM</div>
              </div>
            }
      {this.units()}
             <br />
             <br />
        </div>
      )
                    }}
          </BuilderConsumer>
      )}
}
export default MyLists;