import React, { Component } from 'react';
import BasicField from './basic-field.component'
import BaseContext from '../BaseContext'
import { BaseConsumer } from '../BaseContext'
import axios from 'axios';

class UpdateCustomUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        factions: [],
        unitTypes: [],
        weaponOptions: [],
        Faction: 1,
        UnitType: 1,
        Unit: null,
        Cost: 0,
        Figures: 1,
        Walk: 0,
        Run: 0,
        Jump: 0,
        Melee: 0,
        Focus: 0,
        Pilot: 0,
        Defense: 0,
        Save: 0,
        HP: 0,
        TorsoHP: 0,
        LeftArmHP: 0,
        RightArmHP: 0,
        LegsHP: 0,
        Weapons: [],
        ChosenWeapons: [],
        LoadedWeapons: [],
        CustomWeapons: [],
        CustomSpecials: [],
        LoadedSpecials: [],
        Specials: [],
        SortPriority: 1,
        wep: 'blank',
        image: '',
        spec: 'blank',
        admin: false,
        NamedPilot: false,
        BuyURL: '',
        Weapon1Special: '',
        Weapon2Special: '',
        Weapon3Special: '',
        Weapon4Special: '',
        specialOptions: [],
        id: 0
      };
    if(!localStorage.user_id){
    window.location = "/"
    }
    axios.get('/api/units/' + this.props.match.params.id)
    .then(result => { 
        console.log(result)
    if(localStorage.user_id != result.data.user_fkey){
    window.location = "/"
    } else{
        const u = result.data
        this.setState({Name: u.name, 
            Faction: u.faction_fkey, 
            UnitType: u.unit_type_fkey,
            Cost: u.cost,
            Walk: u.walk_speed,
            Run: u.run_speed,
            Jump: u.jump,
            Melee: u.melee,
            Focus: u.focus,
            Pilot: u.pilot,
            Defense: u.defense,
            Save: u.save,
            TorsoHP: u.torso_hp,
            LeftArmHP: u.left_arm_hp,
            RightArmHP: u.right_arm_hp,
            LegsHP: u.legs_hp,
            HP: u.hp,
            image: u.image
        
        })
    }
    })
    axios.get('/api/weapons/' + this.props.match.params.id)
    .then(result => {
        console.log(result)
        this.setState({LoadedWeapons: result.data.filter(w => !w.user_fkey ), CustomWeapons: result.data.filter(w => w.user_fkey)})
    })
    axios.get('/api/specials/' + this.props.match.params.id)
    .then(result => {
        this.setState({LoadedSpecials: result.data.filter(s => !s.user_fkey ), CustomSpecials: result.data.filter(s => s.user_fkey)})
    })
  }
  
  componentDidMount() {      
    this.setState({id: this.props.match.params.id}, () => console.log(this.state.id))
    axios.get('/api/factions')
    .then(result => {
        this.setState({factions: result.data})
    })
    axios.get('/api/unittypes')
    .then(result => {
        this.setState({unitTypes: result.data})
    })
    
    axios.get('/api/weapons')
    .then(result => {
        this.setState({weaponOptions: result.data})
    })

    
    axios.get('/api/specials')
    .then(result => {
        this.setState({specialOptions: result.data})
    })
  }

  factionOptions = () => {
      
    this.state.factions.map(faction => {
        return(
            <div>{faction.name}</div>
        )
    })
}
handleChange = (e) => {
    if(e.target.value === "----"){
      this.setState({ [e.target.name]: null });
    } else{
  this.setState({ [e.target.name]: e.target.value });
    }
}
handlePilotChange = (e) => {
  this.setState({ [e.target.name]: e.target.checked }, () => console.log(this.state.Special1Pilot));
}

onSubmit = (e) => {
    console.log(this.state)
    e.preventDefault();       
    axios.post('/api/updatecustomunit', this.state)
        .then(response => {
            if(response.data.name=="error"){
            this.setState({error: "Error. Check variable types"})
        } else{
            window.location.reload();
        }
        })
}

updateLocation = (e, index) => {
    var arr = this.state.Weapons
    arr[index].Location = e.target.value
    this.setState({Weapons: arr})
  }

  updateLoadedLocation = (e, index) => {
    var arr = this.state.LoadedWeapons
    arr[index].Location = e.target.value
    this.setState({LoadedWeapons: arr})
  }

removeWeapon = (index) => {
    var newArray = [...this.state.Weapons]
    newArray.splice(index, 1)
    this.setState({Weapons: newArray})
}


addSpecial = (e) => {
    if(this.state.specialOptions[e.target.value]){
          this.setState({ Specials: [...this.state.Specials, this.state.specialOptions[e.target.value]]})
    }
}
removeSpecial = (index) => {
    var newArray = [...this.state.Specials]
    newArray.splice(index, 1)
    this.setState({Specials: newArray})
}
removeLoadedSpecial = (index) => {
    var newArray = [...this.state.LoadedSpecials]
    newArray.splice(index, 1)
    this.setState({LoadedSpecials: newArray})
}
removeCustomSpecial = (index) => {
    var newArray = [...this.state.CustomSpecials]
    newArray.splice(index, 1)
    this.setState({CustomSpecials: newArray})
}

addWeapon = (e) => {
    if(this.state.weaponOptions[e.target.value]){
          this.setState({ Weapons: [...this.state.Weapons, {Weapon: this.state.weaponOptions[e.target.value], Location: ''}]})
    }
}
chosenSpecials = () => {
    return this.state.Specials.map((special, index) => {
        return(
            <div className="special">
                  <div><span className="chosen-special" onClick={() => this.removeSpecial(index)}>X</span> {special.name}</div>
                  <div>{special.description}</div>
            </div>
        )
    })
}
loadedSpecials = () => {
    return this.state.LoadedSpecials.map((special, index) => {
        return(
            <div className="special">
                  <div><span className="chosen-special" onClick={() => this.removeLoadedSpecial(index)}>X</span> {special.name}</div>
                  <div>{special.description}</div>
            </div>
        )
    })
}
handleSpecialChange = (index, par, e) => {
    console.log(e.target.value)
    var specs = this.state.CustomSpecials
    if(par === "name"){
        specs[index].name = e.target.value
    }else if(par === "description"){
        specs[index].description = e.target.value
    }else if(par === "pilot"){
        specs[index].pilot = e.target.checked
    }
    this.setState({CustomSpecials: specs})
}

customSpecials = () => {
    return this.state.CustomSpecials.map((special, index) => {
        return(
            <div className="special">
                  <div><span className="chosen-special" onClick={() => this.removeCustomSpecial(index)}>X</span><input className="card-input" style={{width: "115px"}} onChange={(e) => this.handleSpecialChange(index, "name", e)} placeholder="Name" value={special.name}/></div>
                  <div><input style={{width: "80%"}} className="card-input" value={special.description}  onChange={(e) => this.handleSpecialChange(index, "description", e)}/>   
                  <label>Pilot Ability?</label><input type="checkbox" placeholder="Pilot" name="Special1Pilot" className="card-input" onChange={(e) => this.handleSpecialChange(index, "pilot", e)} checked={this.state.Special1Pilot} />
                  </div>
                 
                    
            </div>
        )
    })
}
uploadImage = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append('file', files[0])
    data.append('upload_preset', 'card_art')
    const res = await fetch(
        'https://api.cloudinary.com/v1_1/dd1mrevav/image/upload',
        {
            method: 'POST',
            body: data
        }
    )
    const file = await res.json()    
    this.setState({image: file.secure_url});
    
}

handleWeaponChange = (index, par, e) => {
   var weps = this.state.CustomWeapons
   if(par === "name"){
       weps[index].name = e.target.value
   }else if(par === "range"){
       weps[index].range = e.target.value
   }else if(par === "rate_of_fire"){
    weps[index].rate_of_fire = e.target.value
    }else if(par === "damage"){
        weps[index].damage = e.target.value
    }else if(par === "special_text"){
        weps[index].special_text = e.target.value
    }else if(par === "location"){
        weps[index].location = e.target.value
    }else if(par === "ammo"){
        weps[index].ammo = e.target.value
    }
   this.setState({CustomWeapons: weps})
}
removeCustomWeapon = (i) => {
    var weps = this.state.CustomWeapons
    weps.splice(i,1)
    this.setState({CustomWeapons: weps})
}
removeLoadedWeapon = (i) => {
    var weps = this.state.LoadedWeapons
    weps.splice(i,1)
    this.setState({LoadedWeapons: weps})
}
customWeapons = () => {
    return this.state.CustomWeapons.map((weapon, index) => {
        return(
            <div class="weapon">
                    <div><span className="chosen-weapon chosen-special" onClick={() => this.removeCustomWeapon(index)}>X</span> <input className="chosen-special chosen-weapon" style={{width: "75px"}} placeholder="Name" name="Name" className="card-input" onChange={(e) => this.handleWeaponChange(index, "name", e)} value={weapon.name} /></div>
                    <div><input style={{width: "100%"}} placeholder="Range" name="Range" className="card-input" onChange={(e) => this.handleWeaponChange(index, "range", e)} value={weapon.range} /></div>
                    <div><input style={{width: "100%"}} placeholder="RoF" name="RateofFire" className="card-input" onChange={(e) => this.handleWeaponChange(index, "rate_of_fire", e)} value={weapon.rate_of_fire} /></div>
                    <div><input style={{width: "100%"}} placeholder="Damage" name="RateofFire" className="card-input" onChange={(e) => this.handleWeaponChange(index, "damage", e)} value={weapon.damage} /></div>
                    <div><input style={{width: "100%"}} placeholder="Special" name="SpecialText" className="card-input" onChange={(e) => this.handleWeaponChange(index, "special_text", e)} value={weapon.special_text} /></div>
                    
                    {this.state.UnitType==1 &&
                    <div>
                        <select name="Weapon1Location" onChange={(e) => this.handleWeaponChange(index, "location", e)} value={weapon.location}>
                            <option value="">-</option>
                            <option value="T">T</option>
                            <option value="RT">RT</option>
                            <option value="LA">LA</option>
                            <option value="RA">RA</option>
                            <option value="Legs">Legs</option>
                            <option value="Tu">Tu</option>
                            <option value="F">F</option>
                            <option value="R">R</option>
                        </select>
                    </div>
                        }
                                
                    {this.state.UnitType==5 &&
                    <div>
                        <select name="Location" onChange={(e) => this.handleWeaponChange(index, "location", e)} value={weapon.location}>
                            <option value="">-</option>
                            <option value="Tu">Tu</option>
                            <option value="F">F</option>
                            <option value="R">R</option>
                        </select>
                    </div>
                        }
                        {this.state.UnitType != 1 && this.state.UnitType != 5 &&
                        <div>-</div>
                        }
                        <div><input style={{width:23}} name="Ammo" className="card-input" onChange={(e) => this.handleWeaponChange(index, "ammo", e)} value={weapon.ammo} /></div>
                </div>
        )
    })
}

chosenWeapons = () => {
    return this.state.Weapons.map((weapon, index) => {
        return(
            <div className="weapon">
                     <div><span className="chosen-weapon" onClick={() => this.removeWeapon(index)} className="chosen-special">X</span>{weapon.Weapon.name}</div>
                     <div>{weapon.Weapon.range}"</div>
                     <div>{weapon.Weapon.rate_of_fire}</div>
                     <div>{weapon.Weapon.damage}</div>
                     <div>{weapon.Weapon.special_text}</div>
                     
                   {this.state.UnitType==1 &&
                   <div>
                       <select name="Weapon1Location" onChange={(e) => this.updateLocation(e, index)}>
                           <option value="">-</option>
                           <option value="T">T</option>
                           <option value="LA">LA</option>
                           <option value="RA">RA</option>
                           <option value="Legs">Legs</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    {this.state.UnitType==5 &&
                   <div>
                       <select name="Weapon1Location" onChange={(e) => this.updateLocation(e, index)}>
                           <option value="">-</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    {this.state.UnitType != 1 && this.state.unitType != 5 &&
                    <div>-</div>
                    }
                     <div>{weapon.Weapon.ammo}</div>
                  </div>
        )
    })
}


loadedWeapons = () => {
    return this.state.LoadedWeapons.map((weapon, index) => {
        return(
            <div className="weapon">
                     <div><span className="chosen-weapon" onClick={() => this.removeLoadedWeapon(index)} className="chosen-special">X</span>{weapon.name}</div>
                     <div>{weapon.range}"</div>
                     <div>{weapon.rate_of_fire}</div>
                     <div>{weapon.damage}</div>
                     <div>{weapon.special_text}</div>
                     
                   {this.state.UnitType==1 &&
                   <div>
                       <select name="Weapon1Location" value={weapon.location} onChange={(e) => this.updateLoadedLocation(e, index) }>
                           <option value="">-</option>
                           <option value="T">T</option>
                           <option value="LA">LA</option>
                           <option value="RA">RA</option>
                           <option value="Legs">Legs</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    {this.state.UnitType==5 &&
                   <div>
                       <select name="Weapon1Location" value={weapon.location} onChange={(e) => this.updateLoadedLocation(e, index)}>
                           <option value="">-</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    {this.state.UnitType != 1 && this.state.unitType != 5 &&
                    <div>-</div>
                    }
                     <div>{weapon.ammo}</div>
                  </div>
        )
    })
}

  render() {
    return (    
        <div className="main">  
        <form onSubmit={this.onSubmit}>
            <div className="unit-info">
   <div>
      <div>
         <div className="unit-card">
            <div className="unit-card-top">
               <div>
               <div className="unit-faction">
            <select value={this.state.Faction} name="Faction" onChange={this.handleChange}>
                {this.state.factions.map(faction => {
                    return(
                        <option value={faction.id}>{faction.name}</option>
                    )
                })}
            </select>
        </div>
                  <div className="unit-name"><input placeholder="Name" name="Name" className="card-input" onChange={this.handleChange} value={this.state.Name} /></div>
                  <div className="unit-type">
            <select value={this.state.UnitType} name="UnitType" onChange={this.handleChange}>
                <option value={null}>----</option>
                {this.state.unitTypes.map(unitType => {
                    return(
                        <option value={unitType.id}>{unitType.name}</option>
                    )
                })}
            </select>
            {this.state.UnitType != 1 && this.state.UnitType != 5 &&
            <span> x<input style={{width:30}} type="number" placeholder="Figures" name="Figures" className="card-input" onChange={this.handleChange} value={this.state.Figures} /></span>
            }
            
        </div>
                  <div className="unit-cost">Cost: <input style={{width:40}} type="number" placeholder="Cost" name="Cost" className="card-input" onChange={this.handleChange} value={this.state.Cost} /></div>
                  <div className="unit-move">Move: <input style={{width:23}} type="number" placeholder="Walk" name="Walk" className="card-input" onChange={this.handleChange} value={this.state.Walk} /> | <input style={{width:23}} type="number" placeholder="Run" name="Run" className="card-input" onChange={this.handleChange} value={this.state.Run} /> <span>| (<input style={{width:23}} type="number" placeholder="Jump" name="Jump" className="card-input" onChange={this.handleChange} value={this.state.Jump} />)</span></div>
                  <div className="unit-melee">Melee: <input style={{width:23}} type="number" placeholder="Melee" name="Melee" className="card-input" onChange={this.handleChange} value={this.state.Melee} /></div>
                  <div className="unit-focus">Focus: <input style={{width:23}} type="number" placeholder="Focus" name="Focus" className="card-input" onChange={this.handleChange} value={this.state.Focus} /></div>
                  <div className="unit-pilot">Pilot: <input style={{width:23}} type="number" placeholder="Pilot" name="Pilot" className="card-input" onChange={this.handleChange} value={this.state.Pilot} />+</div>
               </div>
               <div>
                  <br />
                  <div className="save-wrapper">
                     <div>
                        <div className="defense">Defense: <input style={{width:23}} type="number" placeholder="Defense" name="Defense" className="card-input" onChange={this.handleChange} value={this.state.Defense} />+</div>
                        <div className="save">Save: <input style={{width:23}} type="number" placeholder="Save" name="Save" className="card-input" onChange={this.handleChange} value={this.state.Save} />+</div>
                     </div>
                     {this.state.UnitType == 1 &&
                     <div>Loc Chart</div>
                    }
                  </div>
                  <div className="health">
                      {this.state.UnitType==1 &&
                     <div className="health-wrapper">
                        <div>Torso:<input style={{width:23}} type="number" placeholder="TorsoHP" name="TorsoHP" className="card-input" onChange={this.handleChange} value={this.state.TorsoHP} /></div>
                        <div></div>
                        <div>1-2</div>
                        <div>LA: <input style={{width:23}} type="number" placeholder="LeftArmHP" name="LeftArmHP" className="card-input" onChange={this.handleChange} value={this.state.LeftArmHP} /></div>
                        <div></div>
                        <div>3</div>
                        <div>RA: <input style={{width:23}} type="number" placeholder="RightArmHP" name="RightArmHP" className="card-input" onChange={this.handleChange} value={this.state.RightArmHP} /></div>
                        <div></div>
                        <div>4</div>
                        <div>Legs:<input style={{width:23}} type="number" placeholder="LegsHP" name="LegsHP" className="card-input" onChange={this.handleChange} value={this.state.LegsHP} /></div>
                        <div></div>
                        <div>5-6</div>
                     </div>
                    }
                    {this.state.UnitType != 1 &&
                        <div className="infantry-health-wrapper">
                        <div className="health">HP: <input style={{width:23}} type="number" placeholder="HP" name="HP" className="card-input" onChange={this.handleChange} value={this.state.HP} /></div>
                        <div></div>
                        </div>
                    }
                  </div>
               </div>
            </div>
            <div className="form-group">
            <label>Add Pre-made Weapon:</label>
            <select value={this.state.wep} name="Weapon" onChange={this.addWeapon}>
                <option value="blank">----</option>
                {this.state.weaponOptions.map((weapon, index) => {
                    return(
                        <option value={index}>{weapon.name}</option>
                    )
                })}
            </select>
                </div>
            <div className="weapon-wrapper">
               <div className="weapon-headers">
                  <div>Weapon</div>
                  <div>RNG</div>
                  <div>RoF</div>
                  <div>DMG</div>
                  <div>Specials</div>
                  <div>Loc</div>
                  <div>Ammo</div>
               </div>
              
               <div className="unit-weapons">
                   
                {this.loadedWeapons()}
                {this.chosenWeapons()}
                {this.customWeapons()}
                <div class="weapon">
                     <div><input style={{width: "100%"}} placeholder="Name" name="Weapon1Name" className="card-input" onChange={this.handleChange} value={this.state.Weapon1Name} /></div>
                     <div><input style={{width:23}} type="number" name="Weapon1Range" className="card-input" onChange={this.handleChange} value={this.state.Weapon1Range} />"</div>
                     <div><input style={{width:23}} name="Weapon1ROF" className="card-input" onChange={this.handleChange} value={this.state.Weapon1ROF} /></div>
                     <div><input style={{width:23}} name="Weapon1Damage" className="card-input" onChange={this.handleChange} value={this.state.Weapon1Damage} /></div>
                     <div><input style={{width: "100%"}} name="Weapon1Special" className="card-input" onChange={this.handleChange} value={this.state.Weapon1Specials} /></div>
                     
                   {this.state.UnitType==1 &&
                   <div>
                       <select name="Weapon1Location" onChange={this.handleChange} value={this.state.Weapon1Location}>
                           <option value="">-</option>
                           <option value="T">T</option>
                           <option value="RT">RT</option>
                           <option value="LA">LA</option>
                           <option value="RA">RA</option>
                           <option value="Legs">Legs</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    
                   {this.state.UnitType==5 &&
                   <div>
                       <select name="Weapon1Location" onChange={this.handleChange} value={this.state.Weapon1Location}>
                           <option value="">-</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    {this.state.UnitType != 1 && this.state.UnitType != 5 &&
                    <div>-</div>
                    }
                     <div><input style={{width:23}} type="number" name="Weapon1Ammo" className="card-input" onChange={this.handleChange} value={this.state.Weapon1Ammo} /></div>
                  </div>
                  {this.state.Weapon1Name &&
                  <div class="weapon">
                     <div><input style={{width: "100%"}} placeholder="Name" name="Weapon2Name" className="card-input" onChange={this.handleChange} value={this.state.Weapon2Name} /></div>
                     <div><input style={{width:23}} type="number" name="Weapon2Range" className="card-input" onChange={this.handleChange} value={this.state.Weapon2Range} />"</div>
                     <div><input style={{width:23}} name="Weapon2ROF" className="card-input" onChange={this.handleChange} value={this.state.Weapon2ROF} /></div>
                     <div><input style={{width:23}} name="Weapon2Damage" className="card-input" onChange={this.handleChange} value={this.state.Weapon2Damage} /></div>
                     <div><input style={{width: "100%"}} name="Weapon2Special" className="card-input" onChange={this.handleChange} value={this.state.Weapon2Specials} /></div>
                   {this.state.UnitType==1 &&
                   <div>
                       <select name="Weapon2Location" onChange={this.handleChange} value={this.state.Weapon2Location}>
                           <option value="">-</option>
                           <option value="T">T</option>
                           <option value="RT">RT</option>
                           <option value="LA">LA</option>
                           <option value="RA">RA</option>
                           <option value="Legs">Legs</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    
                   {this.state.UnitType==5 &&
                   <div>
                       <select name="Weapon2Location" onChange={this.handleChange} value={this.state.Weapon2Location}>
                           <option value="">-</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    {this.state.UnitType != 1 && this.state.UnitType != 5 &&
                    <div>-</div>
                    }
                     <div><input style={{width:23}} type="number" name="Weapon2Ammo" className="card-input" onChange={this.handleChange} value={this.state.Weapon2Ammo} /></div>
                  </div>
                }
                {this.state.Weapon2Name &&
                <div class="weapon">
                   <div><input style={{width: "100%"}} placeholder="Name" name="Weapon3Name" className="card-input" onChange={this.handleChange} value={this.state.Weapon3Name} /></div>
                   <div><input style={{width:23}} type="number" name="Weapon3Range" className="card-input" onChange={this.handleChange} value={this.state.Weapon3Range} />"</div>
                   <div><input style={{width:23}} name="Weapon3ROF" className="card-input" onChange={this.handleChange} value={this.state.Weapon3ROF} /></div>
                   <div><input style={{width:23}} name="Weapon3Damage" className="card-input" onChange={this.handleChange} value={this.state.Weapon3Damage} /></div>
                   <div><input style={{width: "100%"}} name="Weapon3Special" className="card-input" onChange={this.handleChange} value={this.state.Weapon3Specials} /></div>
                   {this.state.UnitType==1 &&
                   <div>
                       <select name="Weapon3Location" onChange={this.handleChange} value={this.state.Weapon3Location}>
                           <option value="">-</option>
                           <option value="T">T</option>
                           <option value="RT">RT</option>
                           <option value="LA">LA</option>
                           <option value="RA">RA</option>
                           <option value="Legs">Legs</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    
                   {this.state.UnitType==5 &&
                   <div>
                       <select name="Weapon3Location" onChange={this.handleChange} value={this.state.Weapon3Location}>
                           <option value="">-</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    {this.state.UnitType != 1 && this.state.UnitType != 5 &&
                    <div>-</div>
                    }
                   <div><input style={{width:23}} type="number" name="Weapon3Ammo" className="card-input" onChange={this.handleChange} value={this.state.Weapon3Ammo} /></div>
                </div>
              }
              {this.state.Weapon3Name &&
              <div class="weapon">
                 <div><input style={{width: "100%"}} placeholder="Name" name="Weapon4Name" className="card-input" onChange={this.handleChange} value={this.state.Weapon4Name} /></div>
                 <div><input style={{width:23}} type="number" name="Weapon4Range" className="card-input" onChange={this.handleChange} value={this.state.Weapon4Range} />"</div>
                 <div><input style={{width:23}} name="Weapon4ROF" className="card-input" onChange={this.handleChange} value={this.state.Weapon4ROF} /></div>
                 <div><input style={{width:23}} name="Weapon4Damage" className="card-input" onChange={this.handleChange} value={this.state.Weapon4Damage} /></div>
                 <div><input style={{width: "100%"}} name="Weapon4Special" className="card-input" onChange={this.handleChange} value={this.state.Weapon4Specials} /></div>
                 
                 {this.state.UnitType==1 &&
                   <div>
                       <select name="Weapon4Location" onChange={this.handleChange} value={this.state.Weapon4Location}>
                           <option value="">-</option>
                           <option value="T">T</option>
                           <option value="RT">RT</option>
                           <option value="LA">LA</option>
                           <option value="RA">RA</option>
                           <option value="Legs">Legs</option>
                           <option value="Tu">Tu</option>
                           <option value="R">R</option>
                           <option value="F">F</option>
                       </select>
                   </div>
                    }
                    
                   {this.state.UnitType==5 &&
                   <div>
                       <select name="Weapon4Location" onChange={this.handleChange} value={this.state.Weapon4Location}>
                           <option value="">-</option>
                           <option value="Tu">Tu</option>
                           <option value="F">F</option>
                           <option value="R">R</option>
                       </select>
                   </div>
                    }
                    {this.state.UnitType != 1 && this.state.UnitType != 5 &&
                    <div>-</div>
                    }
                 <div><input style={{width:23}} type="number" name="Weapon4Ammo" className="card-input" onChange={this.handleChange} value={this.state.Weapon4Ammo} /></div>
              </div>
            }
                  </div>
                  </div>
            
                  <div className="form-group">
            <label>Specials</label>
            <select value="blank" name="Special" onChange={this.addSpecial}>
                <option value={null}>----</option>
                {this.state.specialOptions.map((special, index) => {
                    return(
                        <option value={index}>{special.name}</option>
                    )
                })}
            </select>
            </div>
            <div class="specials-title">Keywords</div>
            <div class="unit-specials">
            {this.loadedSpecials()}
            {this.chosenSpecials()}
            {this.customSpecials()}
               <div class="special">
                  <div><input style={{width: "100%"}} placeholder="Name" name="Special1Name" className="card-input" onChange={this.handleChange} value={this.state.Special1Name} /></div>
                  <div><input style={{width: "80%"}} placeholder="Description" name="Special1Description" className="card-input" onChange={this.handleChange} value={this.state.Special1Description} />
                  <label>Pilot Ability?</label><input type="checkbox" placeholder="Pilot" name="Special1Pilot" className="card-input" onChange={this.handlePilotChange} checked={this.state.Special1Pilot} />
                  
                  </div>
               </div>
               
               {this.state.Special1Name &&
               <div class="special">
                  <div><input style={{width: "100%"}} placeholder="Name" name="Special2Name" className="card-input" onChange={this.handleChange} value={this.state.Special2Name} /></div>
                  <div><input style={{width: "80%"}} placeholder="Description" name="Special2Description" className="card-input" onChange={this.handleChange} value={this.state.Special2Description} />
                  <label>Pilot Ability?</label><input type="checkbox" placeholder="Pilot" name="Special2Pilot" className="card-input" onChange={this.handlePilotChange} checked={this.state.Special2Pilot} />
                  
                  </div>
               </div>
                }
                {this.state.Special2Name &&
                <div class="special">
                   <div><input style={{width: "100%"}} placeholder="Name" name="Special3Name" className="card-input" onChange={this.handleChange} value={this.state.Special3Name} /></div>
                   <div><input style={{width: "80%"}} placeholder="Description" name="Special3Description" className="card-input" onChange={this.handleChange} value={this.state.Special3Description} />
                   <label>Pilot Ability?</label><input type="checkbox" placeholder="Pilot" name="Special3Pilot" className="card-input" onChange={this.handlePilotChange} checked={this.state.Special3Pilot} />
                  
                   </div>
                </div>
                 }
                 {this.state.Special4Name &&
                 <div class="special">
                    <div><input style={{width: "100%"}} placeholder="Name" name="Special4Name" className="card-input" onChange={this.handleChange} value={this.state.Special4Name} /></div>
                    <div><input style={{width: "80%"}} placeholder="Description" name="Special4Description" className="card-input" onChange={this.handleChange} value={this.state.Special4Description} />
                    <label>Pilot Ability?</label><input type="checkbox" placeholder="Pilot" name="Special4Pilot" className="card-input" onChange={this.handlePilotChange} checked={this.state.Special4Pilot} />
                  
                    </div>
                 </div>
                  }
            </div>
         </div>
      </div>
   </div>
   <div style={{textAlign:'center'}}>
            <label>Unit Image </label> 
        <input type="file"
                    name="file"
                    placeholder="Upload an image"
                    onChange={this.uploadImage}
                /><br />
            <img src={this.state.image} /><br /><br />
            </div>
   
   <div className="error">
            {this.state.error}
        </div>
        <div className="form-group">
            <input type="submit" value="Save Unit" className="btn btn-primary" />
        </div>
</div>
        </form>
    </div>
    );
  }
}
export default UpdateCustomUnit;