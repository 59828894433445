
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseContext from '../BaseContext'
import { BaseConsumer } from '../BaseContext'
import axios from 'axios';
import '../styles/navbar.css';

class EditSubfactionsList extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        subfactions: [],
        error: ''
    }    
    axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
    axios.get('/api/subfactions')
    .then(res => {
        this.setState({subfactions: res.data})
    })
  }
  delete = (id) => {
      var subfaction = {id: id}
      axios.post('/api/subfactiondelete', subfaction)
      .then(result => {
            if(!result.data.passed){
                var units = ''
                result.data.units.map(u => units+=u.name + ", ")
                this.setState({error: "Must remove this subfaction from " + units})
            } else{
                window.location = "/editsubfactions"
            }
      })
  }
  subfactions = () => {
      return this.state.subfactions.map((subfaction) => {
          return(
          <div><Link to={`/updatesubfaction/${subfaction.id}`}>{subfaction.name}</Link> {this.state.admin &&<span onClick={() => this.delete(subfaction.id)}>X</span>}</div>
          )
      })
  }
  render() {
    return (    
        
        <BaseConsumer>
         {props => {
                    return(
        <div>  
        {this.state.moderator &&
        <div>
            {this.state.error}
            {this.subfactions()}
           </div>
        }
        </div>
                    )}}
        </BaseConsumer>
    );
  }
}
export default EditSubfactionsList;

