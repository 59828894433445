import React, { Component } from 'react';
import axios from 'axios';
import '../styles/login.css';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
        password: '',
        password2: '',
        code: this.props.match.params.code,
        error: ''
    }
  }
  componentDidMount(){
  }
  resetPassword = (event) => {
      event.preventDefault();
      if(this.state.password == this.state.password2){
        const user = {
            code: this.state.code,
            password: this.state.password
        }
        axios.post('/auth/confirmreset', {
            withCredentials: true,
            crossDomain: true,
            data: user
        },
        user)
        .then(result => {
            window.location = "/signin"
        })      
    } else{
        this.setState({error: "Passwords do not match"});
    }
  }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

  render() {
    return (    
        <div className="login">  
            <div className="login-form">
                <form class="form" onSubmit={this.resetPassword}>
                    {this.state.error}
                    <input value={this.state.password} name="password" onChange={this.handleChange} type="password" id="password" placeholder="pw" required />  <br/>
                    <input value={this.state.password2} name="password2" onChange={this.handleChange} type="password" id="password2" placeholder="pw" required />  <br/>
                    <button type="submit">Change Password</button>              
                </form>
            </div>
        </div>
    );
  }
}
export default PasswordReset;