
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BaseContext from '../BaseContext'
import { BaseConsumer } from '../BaseContext'
import axios from 'axios';
import '../styles/navbar.css';

class EditModelsList extends Component {
  static contextType = BaseContext    
  constructor(props) {
    super(props);
    this.state = {
        admin: false,
        moderator: false,
        models: [],
        error: ''
    }    
    axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
    axios.get('/api/adminmodels')
    .then(res => {
        this.setState({models: res.data})
    })
  }
  delete = (id) => {
      var model = {id: id}
      axios.post('/api/modeldelete', model)
      .then(result => {
            window.location = "/editmodels"
      })
  }
  models = () => {
      return this.state.models.map((model) => {
          return(
              <div><Link to={`/updatemodel/${model.id}`}>{model.name}</Link> <span onClick={() => this.delete(model.id)}>X</span></div>
          )
      })
  }
  render() {
    return (    
        
        <BaseConsumer>
         {props => {
                    return(
        <div>  
        {this.state.moderator &&
        <div>
            {this.state.error}
            {this.models()}
           </div>
        }
        </div>
                    )}}
        </BaseConsumer>
    );
  }
}
export default EditModelsList;

