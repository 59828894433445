import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Title: '',
        Body: '',
        admin: false
      };
      axios.get('/auth/checkadmin')
      .then(res => {
          if(!res.data.admin){
            window.location = "/"
          } else{
            this.setState({admin: true})
          }
      })
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
onSubmit = (e) => {
    e.preventDefault();       
    var update = {
        title: this.state.Title,
        body: this.state.Body
    }
    axios.post('/api/update', update)
            .then(response => {
                this.setState({Title: '', Body: ''})
            })
}
  render() {
    return (    
        <div>  
          {this.state.admin &&
            <form onSubmit={this.onSubmit}>
            <BasicField label="Title" valueState={this.state.Title} onChangeState={this.handleChange} /> 
            <div className="form-group">
            <label>Body</label>
            <textarea 
            type="text"
            className="form-control"
            value={this.state.Body}
            onChange={this.handleChange}
            name="Body"
            style={{height: 200}}
            />
        </div>          
            <div className="form-group">
                <input type="submit" value="Post Update" className="btn btn-primary" />
            </div>
            </form>    
        }
        </div>
    );
  }
}
export default CreateUpdate;