import React, { Component } from 'react';
import BasicField from './basic-field.component'
import axios from 'axios';

class UpdateWeapon extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Name: '',
        Description: '',
        Range: 0,
        RateOfFire: 0,
        Damage: 0,
        Ammo: 0,
        admin: false,
        moderator: false
      };
      axios.get('/auth/checkmoderator')
    .then(res => {
        if(!res.data.admin && !res.data.moderator){
          window.location = "/"
        } else{
          this.setState({admin: res.data.admin, moderator: res.data.moderator})
        }
    })
  }
  
  componentDidMount() { 
    axios.get('/api/weapon/' + this.props.match.params.id)
    .then(result => {
        this.setState({Name: result.data.name, Special: result.data.special_text, Range: result.data.range, RateOfFire: result.data.rate_of_fire, Damage: result.data.damage, Ammo: result.data.ammo})
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

onSubmit = (e) => {
    e.preventDefault();       
    var weapon = {
        id: this.props.match.params.id,
        name: this.state.Name,
        special_text: this.state.Special,
        damage: this.state.Damage,
        ammo: this.state.Ammo,
        rate_of_fire: this.state.RateOfFire,
        range: this.state.Range
    }
    axios.post('/api/updateweapon', weapon)
    .then(response => {
      if(response.data.name=="error"){
        this.setState({error: "Error. Check variable types"})
    } else{
        window.location.reload();
    }
  })
}

  render() {
    return (    
        <div>  
            {this.state.moderator &&
        <form onSubmit={this.onSubmit}>
        <BasicField label="Name" valueState={this.state.Name} onChangeState={this.handleChange} />  
        <div className="form-group">
            <label>Special</label>
            <textarea 
            type="text"
            className="form-control"
            value={this.state.Special}
            onChange={this.handleChange}
            name="Special"
            style={{height: 100}}
            />
        </div> 
        <BasicField label="Range" valueState={this.state.Range} onChangeState={this.handleChange} />  
        <BasicField label="RateOfFire" valueState={this.state.RateOfFire} onChangeState={this.handleChange} />  
        <BasicField label="Damage" valueState={this.state.Damage} onChangeState={this.handleChange} />  
        <BasicField label="Ammo" valueState={this.state.Ammo} onChangeState={this.handleChange} />  
        <div className="error">
            {this.state.error}
        </div>
        <div className="form-group">
            <input type="submit" value="Update Weapon" className="btn btn-primary" />
        </div>
    </form>
    }
    
    </div>
    );
  }
}
export default UpdateWeapon;